import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';
import { goodsInventoriesService } from '../../services/GoodsInventoriesService';
import ReactPaginate from 'react-paginate';

class GoodsInventories extends Component {
  constructor(props) {
    super(props);

    this.state = {
      goodsInventories: [],
      searchGoodInventories: {
        title: ''
      },
      goodsInventoriesPagination: {}
    };
  }

  componentWillMount() {
    goodsInventoriesService
      .getAll()
      .then(jsonResponse => {
        this.setGoodsInventoriesState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  setGoodsInventoriesState = response => {
    let goodsInventories = { ...this.state.goodsInventories };
    let goodsInventoriesPagination = {
      ...this.state.goodsInventoriesPagination
    };
    goodsInventories = response.goods_inventories;
    goodsInventoriesPagination = response.pagination;

    this.setState({ goodsInventories });
    this.setState({ goodsInventoriesPagination });
  };

  handlePageClick = selectedPage => {
    if (this.state.searchGoodInventories.title) {
      this.search(selectedPage.selected + 1);
    } else {
      goodsInventoriesService
        .getAll(selectedPage.selected + 1)
        .then(jsonResponse => {
          this.setGoodsInventoriesState(jsonResponse);
        })
        .catch(error => console.log(error));
    }
  };

  updateField = event => {
    const field = event.target.name;
    const searchGoodInventories = this.state.searchGoodInventories;
    searchGoodInventories[field] = event.target.value;

    this.setState({
      searchGoodInventories
    });
  };

  search = (pageNumber = 1) => {
    goodsInventoriesService
      .search(this.state.searchGoodInventories, pageNumber)
      .then(jsonResponse => {
        this.setGoodsInventoriesState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  render() {
    return (
      <div className="standard-page">
        <MetaTags>
          <title>
            Inventarul Bunurilor din domeniul public al unitatii administrativ -
            teritoriale.
          </title>
          <meta
            name="description"
            content="Inventarul Bunurilor din domeniul public al unitatii administrativ - teritoriale Frătăuții Vechi. Aici este prezentat Inventarul Bunurilor din domeniul public al unitatii administrativ - teritoriale din Frătăuții Vechi."
          />
          <meta
            name="keywords"
            content="primaria Frătăuții Vechi, Frătăuții Vechi, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare"
          />
          <meta
            property="og:title"
            content="Inventarul Bunurilor din domeniul public al unitatii administrativ - teritoriale"
          />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Despre > </h4>
            <h4 className="standard__generic">Inventar Bunuri</h4>
          </div>
          <h2 className="standard__main-title">
            Inventarul Bunurilor din Domeniul Public al Unității
            Administrativ-Teritoriale
          </h2>
        </div>
        <div className="standard__wrapper">
          <div className="standard__input-box standard__mp-input-box">
            <input
              className="standard__input standard__mp-input"
              label="Cautare Document"
              placeholder="Căutare Document"
              name="title"
              ovalue={this.state.searchGoodInventories.title}
              onChange={this.updateField}
            />
            <img
              src="/images/icons/search.svg"
              className="standard__search-icon mariage-publications__search-icon"
              alt="search-icon"
              onClick={() => this.search()}
            />
          </div>
          <div
            className={
              'standard__cards ' +
              (this.state.goodsInventories.length <= 2
                ? 'standard__projects-wrapper-few'
                : '')
            }
          >
            {this.state.goodsInventories &&
            this.state.goodsInventories.length > 0 ? (
              this.state.goodsInventories.map(goodsInventory => (
                <div
                  key={goodsInventory.id}
                  className="standard__individual-card"
                >
                  <h3 className="standard__card-title">
                    {goodsInventory.title}
                  </h3>
                  <h4
                    className="standard__see-project"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${
                          goodsInventory.document.url
                        }`
                      )
                    }
                  >
                    Vizualizează Document
                  </h4>
                  <img
                    src="/images/icons/file.svg"
                    className="standard__card-img"
                    alt="file"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${
                          goodsInventory.document.url
                        }`
                      )
                    }
                  />
                </div>
              ))
            ) : (
              <div className="standard__no-projects-box">
                <p className="standard__no-projects">
                  Informații în curs de actualizare.
                </p>
              </div>
            )}
          </div>
          {this.state.goodsInventoriesPagination.last_page > 1 && (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.goodsInventoriesPagination.last_page}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handleGoodsInventoriesPageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          )}
        </div>
      </div>
    );
  }
}

export default GoodsInventories;
