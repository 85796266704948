import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const balanceSheetsService = {
  getAll,
  deleteBalanceSheet,
  createOrUpdate
};

const requestOptions = {
  method: 'GET',
  headers: Object.assign({
    Accept: 'application/json',
    'Content-type': 'application/json'
  })
};

function getAll(pageNumber) {
  let url = `${
    process.env.REACT_APP_API_URL
  }/api/balance_sheets/?page[number]=${pageNumber ? pageNumber : 1}`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}

function createOrUpdate(newBalanceSheet) {
  const createRequestOptions = {
    method: newBalanceSheet.id ? 'PUT' : 'POST',
    headers: {
      Accept: 'application/json'
    },
    body: createBodyRequest(newBalanceSheet)
  };

  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    createRequestOptions.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }

  const url = `${process.env.REACT_APP_API_URL}/api/balance_sheets${
    newBalanceSheet.id ? `/${newBalanceSheet.id}` : ''
  }`;

  return fetch(url, createRequestOptions).then(
    responseHandleService.handleResponse
  );
}

function createBodyRequest(balanceSheets) {
  let formData = new FormData();

  formData.append('balance_sheet[title]', balanceSheets.title);
  formData.append('balance_sheet[add_date]', balanceSheets.date);

  const document = balanceSheets.document;

  if (document) {
    if (document.url) {
      formData.append(`balance_sheet[document]`, document.url);
    } else {
      formData.append(`balance_sheet[document]`, document, document.name);
    }
  }

  return formData;
}

function deleteBalanceSheet(balanceSheetId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };

  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${
    process.env.REACT_APP_API_URL
  }/api/balance_sheets/${balanceSheetId}`;

  return axios.delete(url, requestOptionsDelete);
}
