import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';
import { transparentEarningService } from '../../services/TransparencyEarningsService';

class TransparentEarnings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      documents: [],
      pagination: {}
    };
  }

  componentWillMount() {
    transparentEarningService
      .getAll()
      .then(jsonResponse => {
        console.log(jsonResponse);
        this.setTransparentEarningState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  handlePageClick = selectedPage => {
    transparentEarningService
      .getAll(selectedPage.selectedPage + 1)
      .then(jsonResponse => {
        this.setTransparentEarningState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  setTransparentEarningState = response => {
    let documents = { ...this.state.documents };
    let pagination = { ...this.state.pagination };
    documents = response.public_interests;
    pagination = response.pagination;

    this.setState({ documents });
    this.setState({ pagination });

    console.log(this.state.documents);
  };
  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Transparență Venituri Salariale</title>
          <meta
            name="description"
            content="Transparenta Venituri Salariale Frătăuții Vechi. Aici este prezentata transparenta veniturilor salariale din Frătăuții Vechi."
          />
          <meta
            name="keywords"
            content="primaria Frătăuții Vechi, Frătăuții Vechi, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta property="og:title" content="Transparenta Venituri Salariale" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Interes Public > </h4>
            <h4 className="standard__generic">
              Transparență Venituri Salariale
            </h4>
          </div>
          <h2 className="standard__main-title">
            Situația drepturilor salariale stabilite potrivit legii
          </h2>
        </div>
        <div className="standard__wrapper wrapper-no-search">
          <div className="standard__cards standard__projects-wrapper-few">
            <div className="standard__individual-card">
              <h3 className="standard__card-title">
                Transparenta Venituri Salariale Martie 2021
              </h3>
              <h4
                className="standard__see-project"
                onClick={() =>
                  window.open(
                    '/documents/Transparenta-venituri-salariale-martie-2021.pdf',
                    '_blank'
                  )
                }
              >
                Vizualizează
              </h4>
              <img
                src="/images/icons/file.svg"
                className="standard__card-img standard__card-img-local"
                alt="file"
                onClick={() =>
                  window.open(
                    '/documents/Transparenta-venituri-salariale-martie-2021.pdf',
                    '_blank'
                  )
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TransparentEarnings;
