// @flow

import React, { Component } from 'react';
import '../AboutVicov.css';

class AccessWays extends Component {
  render() {
    return (
      <div className="about-vicov__container">
        <div className="about-vicov__container-inside">
          <div className="about-vicov__banner">
            <img
              className="about-vicov__banner-img"
              id="aa-banner-img"
              src="images/bannerImages/vicovu_de_sus_001.jpg"
              alt="general-vicov"
            />
            <div className="about-vicov__close-modal" id="aa-close-modal">
              <span>
                <img
                  onClick={() => this.props.closeModal()}
                  src="images/icons/close.svg"
                  alt="close Icon"
                />
              </span>
            </div>
          </div>
          <h3 className="standard__submitted-title standard__civil-status-title">
            Cai de access
          </h3>
          <div className="about-vicov__content">
            <div className="about-vicov__left-content">
              <h3 className="standard__submitted-title" id="gp-subtitle">
                Caile de access
              </h3>
              <h4 className="standard__generic aa-info" id="gp-description">
                Principalele căi de acces sunt reprezentate de DN 2E ., care
                realizează legătura orașului Frătăuții Vechi cu municipiul
                Radauti și DN 2H , DJ 209 G , care străbate orașul de la est la
                vest , intersectând DN 2 E în interiorul localității. Aceste
                drumuri sunt foarte importante pentru circulaţie, importanţă
                sporită și de numărul mare de navetişti care folosesc aceste
                drumuri.
              </h4>
              <h4 className="standard__generic aa-info" id="gp-description">
                Reţeaua de străzi mici, parcări, trotuare, administrată de
                Consiliul Local Frătăuții Vechi , este în prezent în lungime de
                aproximativ 84 km, din care 84 km drumuri și 3,2 km trotuare.
              </h4>
              <div className="table">
                <div className="table__row table__row--green table__row--header">
                  <div className="text">Categorie drum</div>
                  <div className="text">Numar</div>
                  <div className="text">Total km drumuri</div>
                  <div className="text">Total km trotuare</div>
                  <div className="text">Total km alei pietonale</div>
                  <div className="text">Numar locuri de parcare</div>
                </div>
                <div className="table__row">
                  <div className="text">Drum comunal</div>
                  <div className="text">-</div>
                  <div className="text">-</div>
                  <div className="text">-</div>
                  <div className="text">-</div>
                  <div className="text">-</div>
                </div>
                <div className="table__row">
                  <div className="text">Drum vecinal</div>
                  <div className="text">18</div>
                  <div className="text">19.2</div>
                  <div className="text">-</div>
                  <div className="text">-</div>
                  <div className="text">-</div>
                </div>
                <div className="table__row">
                  <div className="text">Strazi</div>
                  <div className="text">106</div>
                  <div className="text">84</div>
                  <div className="text">3.3</div>
                  <div className="text">-</div>
                  <div className="text">180</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">TOTAL</div>
                  <div className="text text--final">124</div>
                  <div className="text text--final">103.2</div>
                  <div className="text text--final">3.3</div>
                  <div className="text text--final">-</div>
                  <div className="text text--final">180</div>
                </div>
              </div>
              <h5>
                Structura rețelei de străzi mici, parcări, trotuare din orașul
                Frătăuții Vechi
              </h5>
              <h4 className="standard__generic aa-info" id="gp-description">
                Starea tehnică a drumurilor în orasul Frătăuții Vechi este
                precară, doar 30% din drumuri fiind asfaltate. Practic, cea mai
                mare parte din drumuri nu este modernizată, drumurile sunt
                pietruite, cu balast sau balast în amestec cu pietriș și se
                încadrează in categoria de trafic ușor. Accesul pietonal este
                slab dezvoltat, nu sunt amenajate trotuare decât pe 3.2 km,
                acostamentele sunt în marea majoritate din pământ înierbat.
              </h4>
            </div>
            <div className="about-vicov__right-content">
              <h4 className="standard__generic aa-info" id="gp-description">
                În ceea ce privește sistemul de colectare și evacuare ape
                pluviale aferent drumurilor, acesta este ineficient, deoarece
                este realizat din șanțuri de pământ, in mare parte colmatate. În
                aceste condiții, nu se realizeaza un drenaj corespunzător al
                apelor pluviale și prin urmare starea drumurilor este direct
                afectată.
              </h4>
              <div className="table">
                <div className="table__row table__row--green table__row--header">
                  <div className="text">Categorie drum</div>
                  <div className="text">
                    Total lungime drumuri (km), din care:
                  </div>
                  <div className="text">Drumuri asfaltate</div>
                  <div className="text">Drumuri de pamant</div>
                  <div className="text">Drumuri reabilitate(2008 - 2013)</div>
                </div>
                <div className="table__row">
                  <div className="text">Drum comunal</div>
                  <div className="text">-</div>
                  <div className="text">-</div>
                  <div className="text">-</div>
                  <div className="text">-</div>
                </div>
                <div className="table__row">
                  <div className="text">Drum vecinal</div>
                  <div className="text">18</div>
                  <div className="text">-</div>
                  <div className="text">18</div>
                  <div className="text">-</div>
                </div>
                <div className="table__row">
                  <div className="text">Strazi</div>
                  <div className="text">84</div>
                  <div className="text">34</div>
                  <div className="text">50</div>
                  <div className="text">11</div>
                </div>
                <div className="table__row">
                  <div className="text text--final">TOTAL</div>
                  <div className="text text--final">102</div>
                  <div className="text text--final">34</div>
                  <div className="text text--final">68</div>
                  <div className="text text--final">11</div>
                </div>
              </div>
              <h3 className="standard__submitted-title" id="gp-subtitle">
                Transportul
              </h3>
              <h4 className="standard__generic aa-info" id="gp-description">
                Transport feroviar în orașul Frătăuții Vechi există – calea
                ferata nefunctionala, cea mai apropiată stație feroviară fiind
                în orașul Suceava la circa 65 km.
              </h4>
              <h4 className="standard__generic aa-info" id="gp-description">
                Transportul public de persoane interjudeţean Suceava – celelalte
                localități vecine Straja , Vicovu de Jos , Putna , Bilca este
                asigurat de mai multi operatori SC Rotma SRL; SC Tarsin SRL; SC
                Tasa Suceava , cu mașinile din dotare- autocare, autobuze,
                microbuze. Pe teritoriul orașului sunt amenajate - stații de
                autobuz.
              </h4>
              <h3 className="standard__submitted-title" id="gp-subtitle">
                Traficul staţionar
              </h3>
              <h4 className="standard__generic aa-info" id="gp-description">
                In prezent în oraș sunt amenajate doar 180 locuri de parcare în
                regim public.
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AccessWays;
