import React, { Component } from 'react';
import '../../../resources/AdminStandardPage.css';
import AuthenticationService from '../../../services/AuthenticationService';
import DeletePUGCertificates from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import { externalProjectsService } from '../../../services/ExternalProjectsService';
import ReactPaginate from 'react-paginate';

class AdminExternalProjects extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledDocument: false,
      disabledPicture: false,
      newExternalProject: {
        title: '',
        description: '',
        value: '',
        start_date: '',
        end_date: '',
        picture: '',
        documents: ''
      },
      existingProjects: [],
      deleteProject: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };

    this.authentication = new AuthenticationService();
  }

  componentWillMount() {
    externalProjectsService
      .getAll()
      .then(jsonResponse => {
        this.setExternalProjectsState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  handlePageClick = selectedPage => {
    externalProjectsService
      .getAll(selectedPage.selected + 1)
      .then(jsonResponse => {
        this.setExternalProjectsState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  setExternalProjectsState = response => {
    let existingProjects = { ...this.state.existingProjects };
    let pagination = { ...this.state.pagination };
    existingProjects = response.external_projects;
    pagination = response.pagination;

    this.setState({ existingProjects });
    this.setState({ pagination });
  };

  updateField = event => {
    const field = event.target.name;
    const newExternalProject = this.state.newExternalProject;
    newExternalProject[field] = event.target.value;

    this.setState({
      newExternalProject
    });
  };

  onDropPicture = documents => {
    let newExternalProject = { ...this.state.newExternalProject };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newExternalProject.picture = documents[0];

      self.setState({ newExternalProject });
      self.setState({ disabledPicture: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removePicture = () => {
    let newExternalProject = { ...this.state.newExternalProject };
    newExternalProject.picture = null;

    this.setState({ newExternalProject });
    this.setState({ disabledPicture: false });
  };

  onDropDocument = documents => {
    let newExternalProject = { ...this.state.newExternalProject };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newExternalProject.document = documents[0];

      self.setState({ newExternalProject });
      self.setState({ disabledDocument: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let newExternalProject = { ...this.state.newExternalProject };
    newExternalProject.document = null;

    this.setState({ newExternalProject });
    this.setState({ disabledDocument: false });
  };

  resetForm = () => {
    let newExternalProject = { ...this.state.newExternalProject };
    newExternalProject = {
      title: '',
      description: '',
      value: '',
      start_date: '',
      end_date: '',
      picture: '',
      documents: ''
    };

    this.setState({ newExternalProject });
    this.setState({ disabledDocument: false });
    this.setState({ disabledPicture: false });
  };

  handleFormSubmit = event => {
    event.preventDefault();
    const currentId = this.state.newExternalProject.id;

    const headers = {
      Accept: 'application/json'
    };

    if (this.authentication.loggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.authentication.getToken();
    }

    let url = `${process.env.REACT_APP_API_URL}/api/external_projects/`;
    let callMethod = 'POST';

    if (currentId) {
      url = `${process.env.REACT_APP_API_URL}/api/external_projects/${
        this.state.newExternalProject.id
      }`;
      callMethod = 'PUT';
    }

    const formattedData = this.buildFormData();

    fetch(url, {
      method: callMethod,
      body: formattedData,
      headers
    })
      .then(this.handleErrors)
      .then(response => response.json())
      .then(jsonResponse => {
        this.resetForm();

        let existingProjects = this.state.existingProjects.slice();

        let index = existingProjects.findIndex(
          existingProject => existingProject.id === jsonResponse.id
        );

        if (index !== -1) {
          existingProjects[index] = jsonResponse;
        } else {
          existingProjects.push(jsonResponse);
        }

        this.setState({ existingProjects });

        if (currentId) {
          this._notificationSystem.addNotification({
            message: 'Proiectul a fost editat cu success.',
            level: 'success',
            position: 'tc'
          });
        } else {
          this._notificationSystem.addNotification({
            message: 'Proiectul a fost adaugat cu success.',
            level: 'success',
            position: 'tc'
          });
        }
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  buildFormData() {
    let formData = new FormData();
    formData.append(
      'external_project[title]',
      this.state.newExternalProject.title
    );
    formData.append(
      'external_project[description]',
      this.state.newExternalProject.description
    );
    formData.append(
      'external_project[value]',
      this.state.newExternalProject.value
    );
    formData.append(
      'external_project[start_date]',
      this.state.newExternalProject.start_date
    );
    formData.append(
      'external_project[end_date]',
      this.state.newExternalProject.end_date
    );

    if (this.state.newExternalProject.picture) {
      let picture = this.state.newExternalProject.picture;
      formData.append(`external_project[picture]`, picture, picture.name);
    }

    if (this.state.newExternalProject.document) {
      let document = this.state.newExternalProject.document;
      formData.append(`external_project[document]`, document, document.name);
    }

    return formData;
  }

  setProjectForEdit = newProject => {
    let newExternalProject = { ...this.state.newExternalProject };
    newExternalProject = Object.assign({}, newProject);
    this.setState({ newExternalProject });

    if (newProject.document.url) {
      this.setState({ disabledDocument: true });
    }

    if (newProject.picture.url) {
      this.setState({ disabledPicture: true });
    }
  };

  openDeleteDialog = id => {
    let deleteProject = { ...this.state.deleteProject };
    deleteProject = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteProject });
  };

  closeDeleteDialog = () => {
    let deleteProject = { ...this.state.deleteProject };
    deleteProject = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteProject });
  };

  handleDelete = () => {
    externalProjectsService
      .deleteExternalProject(this.state.deleteProject.deleteId)
      .then(() => {
        let existingProjects = this.state.existingProjects.slice();
        existingProjects = existingProjects.filter(
          project => project.id !== this.state.deleteProject.deleteId
        );
        this.setState({ existingProjects });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Proiectul a fost stears cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  handleErrors = response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  };

  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-standard-page__title">
          <h3 className="admin-page__profile-title profile-title-specific">
            Interes Public
          </h3>
          <h1 className="admin-switch-title default-title">
            Proiecte cu finantare externa
          </h1>
        </div>
        <div className="title-line" />
        <div className="admin-standard-page__content">
          <div className="admin-standard-page__create-project">
            <div className="card">
              <form className="admin-form" onSubmit={this.handleFormSubmit}>
                <h2 className="admin-form__title">
                  Adauga un proiect cu finantare externa
                </h2>

                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Titlu proiect*
                  </h4>
                  <input
                    required
                    className="general-field title-field"
                    type="text"
                    name="title"
                    onChange={this.updateField}
                    value={this.state.newExternalProject.title}
                  />

                  <h4 className="label-title label-title-provisions">
                    Descriere proiect*
                  </h4>
                  <textarea
                    required
                    rows="5"
                    className="general-field title-field"
                    type="text"
                    name="description"
                    onChange={this.updateField}
                    value={this.state.newExternalProject.description}
                  />

                  <h4 className="label-title label-title-provisions">
                    Valoare proiect (valoarea este reprezentata in RON)*
                  </h4>
                  <input
                    required
                    className="general-field title-field"
                    type="number"
                    name="value"
                    onChange={this.updateField}
                    value={this.state.newExternalProject.value}
                  />
                  <h4 className="label-title label-title-provisions">
                    Dată inceput*
                  </h4>
                  <input
                    required
                    className="general-field date-field"
                    type="date"
                    name="start_date"
                    onChange={this.updateField}
                    value={this.state.newExternalProject.start_date}
                  />
                  <h4 className="label-title label-title-provisions">
                    Dată finalizare*
                  </h4>
                  <input
                    required
                    className="general-field date-field"
                    type="date"
                    name="end_date"
                    onChange={this.updateField}
                    value={this.state.newExternalProject.end_date}
                  />
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Imagine reprezentativa:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropPicture}
                    removeDocument={this.removePicture}
                    disabledDocuments={this.state.disabledPicture}
                    document={this.state.newExternalProject.picture}
                    documentFilename={
                      this.state.newExternalProject.picture_filename
                    }
                    type="image/*"
                  />
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Documente proiect:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocument}
                    removeDocument={this.removeDocument}
                    disabledDocuments={this.state.disabledDocument}
                    document={this.state.newExternalProject.document}
                    documentFilename={
                      this.state.newExternalProject.document_filename
                    }
                    type="application/pdf"
                  />
                </div>
                <div className="form-buttons">
                  <div className="admin-form__submit">
                    <button
                      type="submit"
                      className="submit-button general-button"
                    >
                      {this.state.newExternalProject.id
                        ? 'Editează Proiect'
                        : 'Adaugă Proiect'}
                    </button>
                  </div>
                  {Object.values(this.state.newExternalProject) && (
                    <button
                      type="button"
                      className="general-button reset-button"
                      onClick={() => this.resetForm()}
                    >
                      Resetează câmpurile
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {this.state.newExternalProject.length > 0 && (
            <div className="admin-standard-page__existing-items">
              <div className="card">
                <div className="existing-items">
                  <h2 className="existing-items__title label-title">
                    Proiecte cu finantare externa existente
                  </h2>
                  <div className="existing-items__content">
                    {this.state.existingProjects.map(project => (
                      <div
                        className="existing-items__content-card"
                        key={project.id}
                      >
                        <div className="card-content provisions-title-content">
                          <h3 className="major-provisions-title">
                            {project.title}
                          </h3>
                        </div>
                        <div className="existing-items__actions-card">
                          <div
                            className="provisions-title-content"
                            onClick={() => this.setProjectForEdit(project)}
                          >
                            <h4 className="action-title"> Editează</h4>
                          </div>

                          <div
                            className="provisions-title-content"
                            onClick={() => this.openDeleteDialog(project.id)}
                          >
                            <h4 className="action-title delete-button">
                              {' '}
                              Șterge
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <DeletePUGCertificates
                    opened={this.state.deleteProject.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteti sigur ca doriti sa stergeti acest proiect?"
                    aditionalText="Dupa stergere, proiectul nu va mai putea fi recuperat."
                  />
                </div>
                {this.state.pagination.last_page > 1 && (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pagination.last_page}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default AdminExternalProjects;
