import React, { Component } from 'react';
import './GeneralPresentation.css';
import '../StandardPage/StandardPage.css';
import PresentationBox from '../../components/PresentationBox/PresentationBox';
import presentationDataBoxes from './presentation-boxes.json';
import MetaTags from 'react-meta-tags';

class GeneralPresentation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      presentationDataBoxes: presentationDataBoxes,
      openBoxById: 0
    };
  }

  openBox = id => {
    this.setState({ openBoxById: id });
  };

  render() {
    return (
      <div className="gp-page">
        <MetaTags>
          <title>Vicovu de Sus Prezentare Generala</title>
          <meta
            name="description"
            content="Vicovu de Sus Prezentare Generala. Aici sunt prezentate informatii Vicovu de Sus."
          />
          <meta
            name="keywords"
            content="primaria Vicovu de Sus, Vicovu de Sus, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare"
          />
          <meta
            property="og:title"
            content="Vicovu de Sus Prezentare Generala"
          />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Prezentare Generala > </h4>
            <h4 className="standard__generic">Vicovu de Sus</h4>
          </div>
          <h2 className="standard__main-title">Vicovu de Sus</h2>
        </div>

        <div className="gp-page__content">
          <ul className="gp-page__hex-grid">
            {this.state.presentationDataBoxes.map(presentationDataBox => (
              <PresentationBox
                key={presentationDataBox.id}
                {...presentationDataBox}
                openedBox={this.state.openBoxById}
                openBox={this.openBox}
              />
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default GeneralPresentation;
