import React from 'react';
import '../StandardPage/StandardPage.css';
import { programsService } from '../../services/ProgramsService';
import { strategiesService } from '../../services/StrategiesService';

class ProgramsStrategies extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      programs: [],
      strategies: [],
      programsPagination: {},
      strategiesPagination: {}
    };
  }

  componentWillMount() {
    programsService
      .getAll()
      .then(jsonResponse => {
        this.setProgramsState(jsonResponse);
      })
      .catch(error => console.log(error));

    strategiesService
      .getAll()
      .then(jsonResponse => {
        this.setStrategiesState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  setProgramsState = response => {
    let programs = { ...this.state.programs };
    let programsPagination = { ...this.state.programsPagination };
    programs = response.programs;
    programsPagination = response.pagination;

    this.setState({ programs });
    this.setState({ programsPagination });
  };

  setStrategiesState = response => {
    let strategies = { ...this.state.strategies };
    let strategiesPagination = { ...this.state.strategiesPagination };
    strategies = response.strategies;
    strategiesPagination = response.pagination;

    this.setState({ strategies });
    this.setState({ strategiesPagination });
  };

  render() {
    return (
      <div className="standard">
        <title>Programe si strategii</title>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Prezentare > </h4>
            <h4 className="standard__generic">Programe și strategii</h4>
          </div>
          <h2 className="standard__main-title">Programe și strategii</h2>
        </div>
        <div className="standard__wrapper heating-aids sa-wrapper">
          <div className="heating-aids__left-side sa-left-side">
            <h3
              className="standard__submitted-title standard__civil-status-title"
              id="programs-title"
            >
              Programe
            </h3>
            <div className="standard__cards" id="programs-cards">
              {this.state.programs && this.state.programs.length > 0 ? (
                this.state.programs.map(program => (
                  <div key={program.id} className="standard__individual-card">
                    <h3 className="standard__card-title">{program.title}</h3>
                    <h4
                      className="standard__see-project"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_API_URL}${
                            program.document.url
                          }`
                        )
                      }
                    >
                      Vizualizează Document
                    </h4>
                    <img
                      src="/images/icons/file.svg"
                      className="standard__card-img"
                      alt="file"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_API_URL}${
                            program.document.url
                          }`
                        )
                      }
                    />
                  </div>
                ))
              ) : (
                <div className="standard__no-projects-box">
                  <p className="standard__no-projects">
                    Informatii in curs de actualizare.
                  </p>
                </div>
              )}
            </div>
          </div>
          <div className="heating-aids__right-side sa-right-side">
            <h3
              className="standard__submitted-title standard__civil-status-title"
              id="left-main-title"
            >
              Strategii
            </h3>
            <div className="standard__cards" id="programs-cards">
              {this.state.strategies && this.state.strategies.length > 0 ? (
                this.state.strategies.map(strategy => (
                  <div key={strategy.id} className="standard__individual-card">
                    <h3 className="standard__card-title sa-title">
                      {strategy.title}
                    </h3>
                    <h4
                      className="standard__see-project"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_API_URL}${
                            strategy.document.url
                          }`
                        )
                      }
                    >
                      Vizualizează Document
                    </h4>
                    <img
                      src="/images/icons/file.svg"
                      className="standard__card-img"
                      alt="file"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_API_URL}${
                            strategy.document.url
                          }`
                        )
                      }
                    />
                  </div>
                ))
              ) : (
                <div className="standard__no-projects-box">
                  <p className="standard__no-projects">
                    Informații in curs de actualizare.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ProgramsStrategies;
