import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import InputLabel from '@material-ui/core/InputLabel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import './AttachmentField.css';

class AttachmentField extends Component {
  render() {
    const dropzoneStyle = {
      width: '100%',
      border: 'dashed 0.7px #becad6',
      cursor: 'pointer'
    };

    const dropzoneDisabledStyle = {
      width: '100%',
      height: '100%',
      border: 'dashed 0.7px #becad6',
      borderRadius: 5,
      background: 'rgba(255, 255, 255, .5)',
      paddingLeft: '10px'
    };

    return (
      <div className="attachment-form__upload-content">
        <InputLabel>{this.props.attachmentLabel}</InputLabel>
        <Dropzone
          accept={this.props.type}
          multiple={this.props.multiple || false}
          onDrop={this.props.onDrop}
          disabled={this.props.disabledDocuments}
          style={dropzoneStyle}
          disabledStyle={dropzoneDisabledStyle}
        >
          <div className="attachment-form__upload-files">
            <div className="drop-instruction">
              <FontAwesomeIcon
                icon={faCloudUploadAlt}
                className="upload-icon"
              />
              <h3 className="upload-indication">
                Incarca fisier prin tragere sau click
              </h3>
            </div>
            {this.props.document &&
              Object.keys(this.props.document).length > 0 &&
              (this.props.document.url || this.props.document.size) && (
                <div className="attachment-form__existing-files">
                  <ul className="attachment-form__file-list">
                    <li
                      key={
                        this.props.document.name ||
                        this.props.document.file_file_name
                      }
                    >
                      {this.props.document.name || this.props.documentFilename}
                    </li>
                  </ul>
                  <div className="attachment-form__remove-files">
                    <button
                      type="button"
                      className="general-button"
                      onClick={() => this.props.removeDocument()}
                    >
                      Remove Files
                    </button>
                  </div>
                </div>
              )}
            {this.props.documents &&
              this.props.documents.length &&
              this.props.documents.map(document => (
                <div className="attachment-form__existing-files">
                  <ul className="attachment-form__file-list">
                    <li key={document.name || document.file_file_name}>
                      {document.name || document.documentFilename}
                    </li>
                  </ul>
                  <div className="attachment-form__remove-files">
                    <button
                      type="button"
                      className="general-button"
                      onClick={() => this.props.removeDocument()}
                    >
                      Remove Files
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </Dropzone>
      </div>
    );
  }
}

export default AttachmentField;
