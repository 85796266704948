import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const financialSituationService = {
  getAll,
  deleteFinancialSituation,
  search
};
const requestOptions = {
  method: 'GET',
  headers: Object.assign(
    {},
    {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  )
};

function getAll(pageNumber) {
  const requestOptions = {
    method: 'GET',
    headers: Object.assign(
      {},
      {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    )
  };

  let url = `${
    process.env.REACT_APP_API_URL
  }/api/financial_situations/?page[number]=${pageNumber ? pageNumber : 1}`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}
function search(searchFinancialSituation, pageNumber) {
  let searchUrl = `${
    process.env.REACT_APP_API_URL
  }/api/financial_situations/search?title=${
    searchFinancialSituation.title
  }&archiveYear=${searchFinancialSituation.archiveYear}&page[number]=${
    pageNumber ? pageNumber : 1
  }`;

  return fetch(searchUrl, requestOptions).then(
    responseHandleService.handleResponse
  );
}

function deleteFinancialSituation(financialSituationId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${
    process.env.REACT_APP_API_URL
  }/api/financial_situations/${financialSituationId}`;

  return axios.delete(url, requestOptionsDelete);
}
