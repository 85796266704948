import React, { Component } from 'react';
import './EventPage.css';
import EventModal from '../../components/EventModal/EventModal';
import MetaTags from 'react-meta-tags';
import { eventService } from '../../services/EventService';
import '../StandardPage/StandardPage.css';

class EventPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      events: {
        ongoing: true,
        future: false,
        past: false
      },
      eventsInProgress: [],
      eventsUpcoming: [],
      eventsPast: [],
      modalIsOpen: false,
      modalEvent: {}
    };
  }
  togglePanel = eventType => {
    let events = { ...this.state.events };
    Object.keys(events).forEach(key => {
      events[key] = false;
    });

    events[eventType] = !events[eventType];

    this.setState({ events });
  };
  componentWillMount() {
    eventService
      .getAll()
      .then(jsonResponse => {
        this.filterEvents(jsonResponse.events);
      })
      .catch(error => console.log(error));
  }

  filterEvents = events => {
    const currentDate = Date.now();

    if (!events.length) {
      return;
    }

    events.forEach(event => {
      if (
        this.props.match.params.id &&
        this.props.match.params.id === event.id.toString()
      ) {
        this.openModal(event);
      }

      if (
        currentDate > new Date(event.start_date).getTime() &&
        currentDate < new Date(event.end_date).getTime()
      ) {
        let existingInProgress = [...this.state.eventsInProgress];
        event.status = 'progress';
        existingInProgress.push(event);
        this.setState({ eventsInProgress: existingInProgress });

        return;
      }

      if (currentDate > new Date(event.end_date).getTime()) {
        let existingPast = [...this.state.eventsPast];
        event.status = 'past';
        existingPast.push(event);
        this.setState({ eventsPast: existingPast });

        return;
      }

      if (currentDate < new Date(event.start_date).getTime()) {
        let existingUpcoming = [...this.state.eventsUpcoming];
        event.status = 'future';
        existingUpcoming.push(event);
        this.setState({ eventsUpcoming: existingUpcoming });

        return;
      }
    });
  };

  openModal = eventPast => {
    this.setState({ modalIsOpen: true });
    this.setState({ modalEvent: eventPast });
  };

  closeModal = () => {
    this.setState({ modalIsOpen: false });
  };

  render() {
    return (
      <div className="standard-page events">
        <MetaTags>
          <title>Evenimente Frătăuții Vechi</title>
          <meta
            name="description"
            content="Evenimente Frătăuții Vechi. Aici sunt prezentate evenimentele din Frătăuții Vechi."
          />
          <meta
            name="keywords"
            content="primaria Frătăuții Vechi, Frătăuții Vechi, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, Proiecte Finalizate, autorizatii de construire, evenimente"
          />
          <meta property="og:title" content="Evenimente Frătăuții Vechi" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Noutati > </h4>
            <h4 className="standard__generic">Evenimente</h4>
          </div>
          <h2 className="standard__main-title">Evenimente</h2>
        </div>

        <div className="events">
          <div className="events__container">
            <div className="events__titles ">
              <h3
                className={
                  'events__title-section ' +
                  (this.state.events.ongoing ? 'events__title-active' : ' ')
                }
                onClick={e => this.togglePanel('ongoing')}
              >
                Evenimente in desfasurare
              </h3>
              <h3
                className={
                  'events__title-section ' +
                  (this.state.events.future ? 'events__title-active' : ' ')
                }
                onClick={e => this.togglePanel('future')}
              >
                Evenimente viitoare
              </h3>
              <h3
                className={
                  'events__title-section ' +
                  (this.state.events.past ? 'events__title-active' : ' ')
                }
                onClick={e => this.togglePanel('past')}
              >
                Evenimente Trecute
              </h3>
            </div>
            <h3
              className={
                'events__title-section events__only-mobile ' +
                (this.state.events.ongoing ? 'events__title-active' : ' ')
              }
              onClick={e => this.togglePanel('ongoing')}
            >
              Evenimente in desfasurare
            </h3>
            {this.state.events.ongoing ? (
              <div className="events__in-progress">
                {this.state.eventsInProgress.length ? (
                  <div className="events__wrapper">
                    {this.state.eventsInProgress.map(eventInProgress => (
                      <div
                        key={eventInProgress.id}
                        className="events__individual"
                      >
                        <div className="events__date-container">
                          <img
                            src={`${process.env.REACT_APP_API_URL}${
                              eventInProgress.picture.url
                            }`}
                            alt="In progress"
                            className="events__image"
                            onClick={() => this.openModal(eventInProgress)}
                          />
                          <div className="events__date-box">
                            <h4 className="events__date">
                              4-24 noiembrie 1846
                            </h4>
                          </div>
                        </div>
                        <h3 className="events__main-title">
                          {eventInProgress.title}
                        </h3>
                        <p className="events__description">
                          Sed ut perspiciatis unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium, totam
                          rem aperiam, eaque ipsa quae ab illo inventore
                          veritatis et quasi architecto beatae vitae dicta sunt
                          explicabo.{' '}
                        </p>
                        <h4
                          className="events__see-more"
                          onClick={() => this.openModal(eventInProgress)}
                        >
                          Vezi eveniment
                        </h4>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="events__no-events">
                    <p className="events__description events__no-description">
                      Nu sunt evenimente in desfasurare momentan
                    </p>
                  </div>
                )}
              </div>
            ) : null}
            <h3
              className={
                'events__title-section events__only-mobile ' +
                (this.state.events.future ? 'events__title-active' : ' ')
              }
              onClick={e => this.togglePanel('future')}
            >
              Evenimente viitoare
            </h3>
            {this.state.events.future ? (
              <div className="events__upcoming">
                {this.state.eventsUpcoming.length ? (
                  <div className="events__wrapper">
                    {this.state.eventsUpcoming.map(eventUpcoming => (
                      <div
                        key={eventUpcoming.id}
                        className="events__individual"
                      >
                        <div className="events__date-container">
                          <img
                            src={`${process.env.REACT_APP_API_URL}${
                              eventUpcoming.picture.url
                            }`}
                            alt="Upcoming"
                            className="events__image"
                            onClick={() => this.openModal(eventUpcoming)}
                          />
                          <div className="events__date-box">
                            <h4 className="events__date">
                              4-24 noiembrie 1846
                            </h4>
                          </div>
                        </div>
                        <h3 className="events__main-title">
                          {eventUpcoming.title}
                        </h3>
                        <p className="events__description">
                          Sed ut perspiciatis unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium, totam
                          rem aperiam, eaque ipsa quae ab illo inventore
                          veritatis et quasi architecto beatae vitae dicta sunt
                          explicabo.{' '}
                        </p>
                        <h4
                          className="events__see-more"
                          onClick={() => this.openModal(eventUpcoming)}
                        >
                          >Vezi eveniment
                        </h4>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="events__no-events">
                    <p className="events__description events__no-description">
                      Nu sunt evenimente planificate momentan
                    </p>
                  </div>
                )}
              </div>
            ) : null}
            <h3
              className={
                'events__title-section events__only-mobile ' +
                (this.state.events.past ? 'events__title-active' : ' ')
              }
              onClick={e => this.togglePanel('past')}
            >
              Evenimente Trecute
            </h3>
            {this.state.events.past ? (
              <div className="events__past">
                {this.state.eventsPast.length ? (
                  <div className="events__wrapper">
                    {this.state.eventsPast.map(eventsPast => (
                      <div key={eventsPast.id} className="events__individual">
                        <div className="events__date-container">
                          <img
                            src={`${process.env.REACT_APP_API_URL}${
                              eventsPast.picture.url
                            }`}
                            alt="Upcoming"
                            className="events__image"
                            onClick={() => this.openModal(eventsPast)}
                          />
                          <div className="events__date-box">
                            <h4 className="events__date">
                              4-24 noiembrie 1846
                            </h4>
                          </div>
                        </div>
                        <h3 className="events__main-title">
                          {eventsPast.title}
                        </h3>
                        <p className="events__description">
                          Sed ut perspiciatis unde omnis iste natus error sit
                          voluptatem accusantium doloremque laudantium, totam
                          rem aperiam, eaque ipsa quae ab illo inventore
                          veritatis et quasi architecto beatae vitae dicta sunt
                          explicabo.{' '}
                        </p>
                        <h4
                          className="events__see-more"
                          onClick={() => this.openModal(eventsPast)}
                        >
                          >Vezi eveniment
                        </h4>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="events__no-events">
                    <p className="events__description events__no-description">
                      Nu sunt evenimente petrecute momentan
                    </p>
                  </div>
                )}
              </div>
            ) : null}
          </div>
          {Object.keys(this.state.modalEvent).length > 0 && (
            <EventModal
              eventObject={this.state.modalEvent}
              modalIsOpen={this.state.modalIsOpen}
              closeModal={this.closeModal}
            />
          )}
        </div>
      </div>
    );
  }
}

export default EventPage;
