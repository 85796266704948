import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import './PUG.css';
import MetaTags from 'react-meta-tags';
import DownloadCard from '../../components/DownloadCard/DownloadCard';

class PUG extends Component {
  openImageInNewTab = imageUrl => {};

  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Plan Urbanistic General</title>
          <meta
            name="description"
            content="Plan Urbanistic General Frătăuții Vechi. Aici sunt prezentate documentele legate de planul urbanistic general din Frătăuții Vechi."
          />
          <meta
            name="keywords"
            content="primaria Frătăuții Vechi, Frătăuții Vechi, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general"
          />
          <meta property="og:title" content="Plan Urbanistic General" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Servicii > </h4>
            <h4 className="standard__generic">Urbanism</h4>
          </div>
          <h2 className="standard__main-title">Plan Urbanistic General</h2>
        </div>

        <div className="pug-page">
          <div className="standard__wrapper">
            <h3 className="standard__submitted-title pug-page__title-section">
              Documentație Grafică
            </h3>
            <div className="standard__cards standard__projects-wrapper-few">
              <div className="pug-page__graphic-documentation">
                <div className="pug-page__left-side">
                  <div className="standard__card pug-page__card">
                    <DownloadCard
                      flexClass="pug-page__individual-card"
                      title="Elaborare PUZ in scopul lucrari de construire – hala
                      productie prefabricate din beton si imprejmuiri "
                      url="/documents/Memoriu_RLU_PUZ_saturn.pdf"
                    />
                  </div>
                </div>
                <div className="pug-page__right-side">
                  <div className="standard__card pug-page__card">
                    <DownloadCard
                      flexClass="pug-page__individual-card"
                      title="PUZ Saturn Fratautii Vechi"
                      url="/documents/PUZ_saturn_fratautii_vechi.pdf"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PUG;
