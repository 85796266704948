import React, { Component } from 'react';
import './Event.css';
import AuthenticationService from '../../../services/AuthenticationService';
import DeletePUGCertificates from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import { eventService } from '../../../services/EventService';
import ReactPaginate from 'react-paginate';

class Event extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newEvent: {
        title: '',
        description: '',
        start_date: '',
        end_date: '',
        picture: ''
      },
      disabledDocuments: false,
      existingEvents: [],
      deleteEvent: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };

    this.authentication = new AuthenticationService();
  }

  componentWillMount() {
    eventService
      .getAll()
      .then(jsonResponse => {
        this.setEventState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  handlePageClick = selectedPage => {
    eventService
      .getAll(selectedPage.selected + 1)
      .then(jsonResponse => {
        this.setEventState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  setEventState = response => {
    let existingEvents = { ...this.state.existingEvents };
    let pagination = { ...this.state.pagination };
    existingEvents = response.events;
    pagination = response.pagination;

    this.setState({ existingEvents });
    this.setState({ pagination });
  };

  updateField = event => {
    const field = event.target.name;
    const newEvent = this.state.newEvent;
    newEvent[field] = event.target.value;

    this.setState({
      newEvent
    });
  };

  onDropPicture = documents => {
    let newEvent = { ...this.state.newEvent };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newEvent.picture = documents[0];

      self.setState({ newEvent });
      self.setState({ disabledDocuments: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocuments = () => {
    let newEvent = { ...this.state.newEvent };
    newEvent.picture = null;

    this.setState({ newEvent });
    this.setState({ disabledDocuments: false });
  };

  resetForm = () => {
    let newEvent = { ...this.state.newEvent };
    newEvent.title = '';
    newEvent.description = '';
    newEvent.picture = '';

    this.setState({ newEvent });
    this.setState({ disabledDocuments: false });
  };

  handleFormSubmit = event => {
    event.preventDefault();
    const currentId = this.state.newEvent.id;

    const headers = {
      Accept: 'application/json'
    };

    if (this.authentication.loggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.authentication.getToken();
    }

    let url = `${process.env.REACT_APP_API_URL}/api/events/`;
    let callMethod = 'POST';

    if (currentId) {
      url = `${process.env.REACT_APP_API_URL}/api/events/${
        this.state.newEvent.id
      }`;
      callMethod = 'PUT';
    }

    const formattedData = this.buildFormData(this.state.newEvent);

    fetch(url, {
      method: callMethod,
      body: formattedData,
      headers
    })
      .then(this.handleErrors)
      .then(response => response.json())
      .then(jsonResponse => {
        this.resetForm();

        let existingEvents = this.state.existingEvents.slice();

        let index = existingEvents.findIndex(
          event => event.id === jsonResponse.id
        );

        if (index !== -1) {
          existingEvents[index] = jsonResponse;
        } else {
          existingEvents.push(jsonResponse);
        }

        this.setState({ existingEvents });

        if (currentId) {
          this._notificationSystem.addNotification({
            message: 'Evenimentul a fost editat cu success.',
            level: 'success',
            position: 'tc'
          });
        } else {
          this._notificationSystem.addNotification({
            message: 'Evenimentul a fost adaugat cu success.',
            level: 'success',
            position: 'tc'
          });
        }
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  buildFormData() {
    let formData = new FormData();
    formData.append('event[title]', this.state.newEvent.title);
    formData.append('event[description]', this.state.newEvent.description);
    formData.append('event[start_date]', this.state.newEvent.start_date);
    formData.append('event[end_date]', this.state.newEvent.end_date);

    if (this.state.newEvent.picture) {
      let picture = this.state.newEvent.picture;
      formData.append(`event[picture]`, picture, picture.name);
    }

    return formData;
  }

  setEventForEdit = event => {
    let newEvent = { ...this.state.newEvent };
    newEvent = Object.assign({}, event);
    this.setState({ newEvent });
    this.setState({ disabledDocuments: !!this.state.newEvent.picture.url });
  };

  openDeleteDialog = id => {
    let deleteEvent = { ...this.state.deleteEvent };
    deleteEvent = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteEvent });
  };

  closeDeleteDialog = () => {
    let deleteEvent = { ...this.state.deleteEvent };
    deleteEvent = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteEvent });
  };

  handleDelete = () => {
    eventService
      .deleteEvent(this.state.deleteEvent.deleteId)
      .then(() => {
        let existingEvents = this.state.existingEvents.slice();
        existingEvents = existingEvents.filter(
          event => event.id !== this.state.deleteEvent.deleteId
        );
        this.setState({ existingEvents });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Evenimentul a fost stears cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  handleErrors = response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  };

  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-standard-page__title">
          <h3 className="admin-page__profile-title profile-title-specific">
            Noutati
          </h3>
          <h1 className="admin-switch-title default-title">Evenimente</h1>
        </div>
        <div className="title-line" />
        <div className="admin-standard-page__content">
          <div className="admin-standard-page__create-project">
            <div className="card">
              <form className="admin-form" onSubmit={this.handleFormSubmit}>
                <h2 className="admin-form__title">Adauga un eveniment nou</h2>

                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Titlu eveniment*
                  </h4>
                  <input
                    required
                    className="general-field title-field"
                    type="text"
                    name="title"
                    onChange={this.updateField}
                    value={this.state.newEvent.title}
                  />

                  <h4 className="label-title label-title-provisions">
                    Descriere eveniment*
                  </h4>
                  <textarea
                    required
                    className="general-field title-field"
                    type="text"
                    name="description"
                    onChange={this.updateField}
                    value={this.state.newEvent.description}
                  />

                  <h4 className="label-title label-title-provisions">
                    Dată inceput*
                  </h4>
                  <input
                    required
                    className="general-field date-field"
                    type="date"
                    name="start_date"
                    onChange={this.updateField}
                    value={this.state.newEvent.start_date}
                  />
                  <h4 className="label-title label-title-provisions">
                    Dată sfarsit*
                  </h4>
                  <input
                    required
                    className="general-field date-field"
                    type="date"
                    name="end_date"
                    onChange={this.updateField}
                    value={this.state.newEvent.end_date}
                  />
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Adauga imagine pentru eveniment:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropPicture}
                    removeDocument={this.removeDocuments}
                    disabledDocuments={this.state.disabledDocuments}
                    document={this.state.newEvent.picture}
                    documentFilename={this.state.newEvent.picture_filename}
                    type="image/*"
                  />
                </div>
                <div className="form-buttons">
                  <div className="admin-form__submit">
                    <button
                      type="submit"
                      className="submit-button general-button"
                    >
                      {this.state.newEvent.id
                        ? 'Editează Eveniment'
                        : 'Adaugă Eveniment'}
                    </button>
                  </div>
                  {Object.values(this.state.newEvent) && (
                    <button
                      type="button"
                      className="general-button reset-button"
                      onClick={() => this.resetForm()}
                    >
                      Resetează câmpurile
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {this.state.existingEvents.length > 0 && (
            <div className="admin-standard-page__existing-items">
              <div className="card">
                <div className="existing-items">
                  <h2 className="existing-items__title label-title">
                    Evenimente existente
                  </h2>
                  <div className="existing-items__content">
                    {this.state.existingEvents.map(event => (
                      <div
                        className="existing-items__content-card"
                        key={event.id}
                      >
                        <div className="card-content provisions-title-content">
                          <h3 className="major-provisions-title">
                            {event.title}
                          </h3>
                        </div>
                        <div className="existing-items__actions-card">
                          <div
                            className="provisions-title-content"
                            onClick={() => this.setEventForEdit(event)}
                          >
                            <h4 className="action-title"> Editează</h4>
                          </div>

                          <div
                            className="provisions-title-content"
                            onClick={() => this.openDeleteDialog(event.id)}
                          >
                            <h4 className="action-title delete-button">
                              {' '}
                              Șterge
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <DeletePUGCertificates
                    opened={this.state.deleteEvent.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteti sigur ca doriti sa stergeti acest eveniment?"
                    aditionalText="Dupa stergere, evenimentul nu va mai putea fi recuperat."
                  />
                </div>
                {this.state.pagination.last_page > 1 && (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pagination.last_page}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default Event;
