import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const ongoingProjectsService = {
  getAll,
  deleteOngoingProject,
  lastProject,
  search
};
const requestOptions = {
  method: 'GET',
  headers: Object.assign(
    {},
    {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  )
};
function getAll(pageNumber) {
  const requestOptions = {
    method: 'GET',
    headers: Object.assign(
      {},
      {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    )
  };

  let url = `${
    process.env.REACT_APP_API_URL
  }/api/ongoing_projects/?page[number]=${pageNumber ? pageNumber : 1}`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}
function search(searchProject, pageNumber) {
  let searchUrl = `${
    process.env.REACT_APP_API_URL
  }/api/ongoing_projects/search?title=${searchProject.title}&archiveYear=${
    searchProject.archiveYear
  }&page[number]=${pageNumber ? pageNumber : 1}`;

  return fetch(searchUrl, requestOptions).then(
    responseHandleService.handleResponse
  );
}

function lastProject() {
  const requestOptions = {
    headers: {
      Accept: 'application/json'
    }
  };
  let url = `${
    process.env.REACT_APP_API_URL
  }/api/ongoing_projects/last_project`;

  return axios.get(url, requestOptions);
}

function deleteOngoingProject(ongoingProjectId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${
    process.env.REACT_APP_API_URL
  }/api/ongoing_projects/${ongoingProjectId}`;

  return axios.delete(url, requestOptionsDelete);
}
