import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const lawyerService = {
  getAll,
  search,
  deleteLawyerDocument
};

const requestOptions = {
  method: 'GET',
  headers: Object.assign(
    {},
    {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  )
};

function getAll(pageNumber) {
  let url = `${
    process.env.REACT_APP_API_URL
  }/api/lawyer_documents/?page[number]=${pageNumber ? pageNumber : 1}`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}

function search(searchLawyerDocument, pageNumber) {
  let searchUrl = `${
    process.env.REACT_APP_API_URL
  }/api/lawyer_documents/search?title=${
    searchLawyerDocument.title
  }&page[number]=${pageNumber ? pageNumber : 1}`;

  return fetch(searchUrl, requestOptions).then(
    responseHandleService.handleResponse
  );
}

function deleteLawyerDocument(lawyerDocumentId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${
    process.env.REACT_APP_API_URL
  }/api/lawyer_documents/${lawyerDocumentId}`;

  return axios.delete(url, requestOptionsDelete);
}
