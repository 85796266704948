import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import './MonitorOficial.css';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';

class MonitorOficial extends Component {
  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  render() {
    return (
      <div className="standard-page monitor">
        <MetaTags>
          <title>Monitorul Oficial Local</title>
          <meta
            name="description"
            content="Monitorul oficial local al primariei. Aici sunt prezentate detaliile cu privire la Monitorul Oficial Local."
          />
          <meta
            name="keywords"
            content="primarie, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere"
          />
          <meta property="og:title" content="Certificate de Stare Civila" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Interes Public > </h4>
            <h4 className="standard__generic">Monitor Oficial </h4>
          </div>
          <h2 className="standard__main-title">Monitorul Oficial Local</h2>
        </div>

        <div className="standard__wrapper monitor">
          <ul className="monitor__list">
            <a
              href="/legislation/Ordonanta_privind_Statutul_cadru_al_unităţii_administrativ_teritoriale.pdf"
              target="_blank"
              className="monitor__link"
              to="/"
            >
              <h3 className="monitor__subtitle">
                1. STATUTUL UNITATII ADMINISTRATIV-TERITORIALE
              </h3>
            </a>

            <Link
              onClick={this.scrollToTop}
              className="monitor__link"
              to="/regulament-organizare"
            >
              <h3 className="monitor__subtitle">
                2. REGULAMENTELE PRIVIND PROCEDURILE ADMINISTRATIVE
              </h3>
            </Link>
            <h3 className="monitor__subtitle">
              3. HOTĂRÂRILE ADOPTATE DE CONSILIUL LOCAL
            </h3>
            <Link
              onClick={this.scrollToTop}
              className="monitor__link"
              to="/hotarari-adoptate"
            >
              <li>Hotarari Adoptate</li>
            </Link>
            <Link
              onClick={this.scrollToTop}
              className="monitor__link"
              to="/registru-evidenta-proiecte-hotarari"
            >
              <li>Registrul pentru evidența proiectelor de hotarari</li>
            </Link>
            <Link
              onClick={this.scrollToTop}
              className="monitor__link"
              to="/registru-evidenta-hotarari-adoptate"
            >
              <li>Registrul pentru evidența hotărârilor adoptate</li>
            </Link>
            <h3 className="monitor__subtitle">
              {' '}
              4. DISPOZIȚIILE EMISE DE PRIMAR
            </h3>
            <Link
              onClick={this.scrollToTop}
              className="monitor__link"
              to="/dispozitiile-primarului"
            >
              <li>Dispozitiile emise de Primar, cu caracter normativ</li>
            </Link>
            <Link
              onClick={this.scrollToTop}
              className="monitor__link"
              to="/registru-proiecte-dispozitii"
            >
              <li>Registrul pentru evidența proiectelor de dispozitii</li>
            </Link>
            <Link
              onClick={this.scrollToTop}
              className="monitor__link"
              to="/registru-dispozitii"
            >
              <li>Registrul pentru evidența dispozițiilor emise</li>
            </Link>
            <Link
              onClick={this.scrollToTop}
              className="monitor__link"
              to="/informatii-financiare"
            >
              <h3 className="monitor__subtitle">
                5. DOCUMENTE ȘI INFORMAȚII FINANCIARE{' '}
              </h3>
            </Link>
            <h3 className="monitor__subtitle"> 6. ALTE DOCUMENTE</h3>
            <Link
              onClick={this.scrollToTop}
              className="monitor__link"
              to="/registru-inregistrare-refuzuri-acte-administrative"
            >
              <li>
                Registrul privind înregistrarea refuzurilor de a
                semna/contrasemna/aviza actele administrative
              </li>
            </Link>
            <Link
              onClick={this.scrollToTop}
              className="monitor__link"
              to="/consemnare-propuneri-hotarari-si-dispozitii"
            >
              <li>
                Registrul pentru consemnarea propunerilor, sugestiilor sau
                opiniilor cu privire la proiectele de hotărâri și dispoziții cu
                caracter normativ
              </li>
            </Link>
            <Link
              onClick={this.scrollToTop}
              className="monitor__link"
              to="/informare-probleme-interes-public"
            >
              <li>
                Informarea asupra problemelor de interes public care urmează să
                fie dezbătute de autoritățile administrației publice locale
              </li>
            </Link>
            <Link
              onClick={this.scrollToTop}
              className="monitor__link"
              to="/informare-proiecte-acte-administrative"
            >
              <li>
                Informare asupra proiectelor de acte administrative cu caracter
                normativ
              </li>
            </Link>
            <Link
              onClick={this.scrollToTop}
              className="monitor__link"
              to="/proiecte-de-hotarari"
            >
              <li>Proiecte de hotarari</li>
            </Link>
            <Link
              onClick={this.scrollToTop}
              className="monitor__link"
              to="/procese-verbale-sedinte"
            >
              <li>Minute ale ședințelor publice locale</li>
            </Link>
            <Link
              onClick={this.scrollToTop}
              className="monitor__link"
              to="/procese-verbale-sedinte"
            >
              <li>Procesele verbale ale ședințelor Consiliul Local</li>
            </Link>
            <Link
              onClick={this.scrollToTop}
              className="monitor__link"
              to="/publicatii-casatorie"
            >
              <li>Declaraţii de căsătorie</li>
            </Link>
            <Link
              onClick={this.scrollToTop}
              className="monitor__link"
              to="/documente-interes-public"
            >
              <li>Alte documente de interes public</li>
            </Link>
          </ul>
        </div>
      </div>
    );
  }
}

export default MonitorOficial;
