import React, { Component } from 'react';
import '../../../resources/AdminStandardPage.css';
import AuthenticationService from '../../../services/AuthenticationService';
import DeletePUGCertificates from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import { adoptedDecisionsService } from '../../../services/AdoptedDecisionsService';
import ReactPaginate from 'react-paginate';

class AdminAdoptedDecisions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledDocument: false,
      newAdoptedDecision: {
        title: '',
        document: '',
        add_date: '',
        adopted_number: ''
      },
      existingAdoptedDecisions: [],
      deleteAdoptedDecision: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };

    this.authentication = new AuthenticationService();
  }

  componentWillMount() {
    adoptedDecisionsService
      .getAll()
      .then(jsonResponse => {
        this.setAdoptedDecisionState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  handlePageClick = selectedPage => {
    adoptedDecisionsService
      .getAll(selectedPage.selected + 1)
      .then(jsonResponse => {
        this.setAdoptedDecisionState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  setAdoptedDecisionState = response => {
    let existingAdoptedDecisions = { ...this.state.existingAdoptedDecisions };
    let pagination = { ...this.state.pagination };
    existingAdoptedDecisions = response.adopted_decisions;
    pagination = response.pagination;

    this.setState({ existingAdoptedDecisions });
    this.setState({ pagination });
  };

  updateField = event => {
    const field = event.target.name;
    const newAdoptedDecision = this.state.newAdoptedDecision;
    newAdoptedDecision[field] = event.target.value;

    this.setState({
      newAdoptedDecision
    });
  };

  onDropDocument = documents => {
    let newAdoptedDecision = { ...this.state.newAdoptedDecision };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newAdoptedDecision.document = documents[0];

      self.setState({ newAdoptedDecision });
      self.setState({ disabledDocument: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let newAdoptedDecision = { ...this.state.newAdoptedDecision };
    newAdoptedDecision.document = null;
    newAdoptedDecision.document_filename = '';

    this.setState({ newAdoptedDecision });
    this.setState({ disabledDocument: false });
  };

  resetForm = () => {
    let newAdoptedDecision = { ...this.state.newAdoptedDecision };
    newAdoptedDecision = {
      title: '',
      document: '',
      add_date: '',
      adopted_number: ''
    };

    this.setState({ newAdoptedDecision });
    this.setState({ disabledDocument: false });
  };

  handleFormSubmit = event => {
    event.preventDefault();
    const currentId = this.state.newAdoptedDecision.id;

    const headers = {
      Accept: 'application/json'
    };

    if (this.authentication.loggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.authentication.getToken();
    }

    let url = `${process.env.REACT_APP_API_URL}/api/adopted_decisions/`;
    let callMethod = 'POST';

    if (currentId) {
      url = `${process.env.REACT_APP_API_URL}/api/adopted_decisions/${
        this.state.newAdoptedDecision.id
      }`;
      callMethod = 'PUT';
    }

    const formattedData = this.buildFormData();

    fetch(url, {
      method: callMethod,
      body: formattedData,
      headers
    })
      .then(this.handleErrors)
      .then(response => response.json())
      .then(jsonResponse => {
        this.resetForm();

        let existingAdoptedDecisions = this.state.existingAdoptedDecisions.slice();

        let index = existingAdoptedDecisions.findIndex(
          existingAdoptedDecisionDocument =>
            existingAdoptedDecisionDocument.id === jsonResponse.id
        );

        if (index !== -1) {
          existingAdoptedDecisions[index] = jsonResponse;
        } else {
          existingAdoptedDecisions.push(jsonResponse);
        }

        this.setState({ existingAdoptedDecisions });

        if (currentId) {
          this._notificationSystem.addNotification({
            message: 'Hotararea a fost editata cu success.',
            level: 'success',
            position: 'tc'
          });
        } else {
          this._notificationSystem.addNotification({
            message: 'Hotararea a fost adaugata cu success.',
            level: 'success',
            position: 'tc'
          });
        }
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  buildFormData() {
    let formData = new FormData();
    formData.append(
      'adopted_decision[title]',
      this.state.newAdoptedDecision.title
    );

    formData.append(
      'adopted_decision[add_date]',
      this.state.newAdoptedDecision.add_date
    );
    formData.append(
      'adopted_decision[adopted_number]',
      this.state.newAdoptedDecision.adopted_number
    );

    if (this.state.newAdoptedDecision.document_filename) {
      formData.append(
        `adopted_decision[document]`,
        this.state.newAdoptedDecision.document_filename
      );
    } else {
      if (this.state.newAdoptedDecision.document) {
        let document = this.state.newAdoptedDecision.document;
        formData.append(`adopted_decision[document]`, document, document.name);
      }
    }

    return formData;
  }

  setAdoptedDecisionForEdit = newAdoptedDecisionDoc => {
    let newAdoptedDecision = { ...this.state.newAdoptedDecision };
    newAdoptedDecision = Object.assign({}, newAdoptedDecisionDoc);
    this.setState({ newAdoptedDecision });

    if (newAdoptedDecision.document.url) {
      this.setState({ disabledDocument: true });
    }
  };

  openDeleteDialog = id => {
    let deleteAdoptedDecision = { ...this.state.deleteAdoptedDecision };
    deleteAdoptedDecision = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteAdoptedDecision });
  };

  closeDeleteDialog = () => {
    let deleteAdoptedDecision = { ...this.state.deleteAdoptedDecision };
    deleteAdoptedDecision = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteAdoptedDecision });
  };

  handleDelete = () => {
    adoptedDecisionsService
      .deleteAdoptedDecision(this.state.deleteAdoptedDecision.deleteId)
      .then(() => {
        let existingAdoptedDecisions = this.state.existingAdoptedDecisions.slice();
        existingAdoptedDecisions = existingAdoptedDecisions.filter(
          adoptedDecision =>
            adoptedDecision.id !== this.state.deleteAdoptedDecision.deleteId
        );
        this.setState({ existingAdoptedDecisions });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Hotararea a fost stearsa cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema la adaugare, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  handleErrors = response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  };

  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-standard-page__title">
          <h3 className="admin-page__profile-title profile-title-specific">
            Administrație
          </h3>
          <h1 className="admin-switch-title default-title">
            Hotarari adoptate
          </h1>
        </div>
        <div className="title-line" />
        <div className="admin-standard-page__content">
          <div className="admin-standard-page__create-project">
            <div className="card">
              <form className="admin-form" onSubmit={this.handleFormSubmit}>
                <h2 className="admin-form__title">
                  Adaugă o hotarare adoptata noua
                </h2>

                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Titlu hotărâre adoptată*
                  </h4>
                  <input
                    className="general-field title-field"
                    type="text"
                    name="title"
                    onChange={this.updateField}
                    value={this.state.newAdoptedDecision.title}
                  />
                  <h4 className="label-title label-title-provisions">
                    Dată hotărâre*
                  </h4>
                  <input
                    className="general-field date-field"
                    type="date"
                    name="add_date"
                    onChange={this.updateField}
                    value={this.state.newAdoptedDecision.add_date}
                  />
                  <h4 className="label-title label-title-provisions">
                    Număr decizie*
                  </h4>
                  <label>
                    <input
                      required
                      className="general-field date-field"
                      id="number-field"
                      type="number"
                      name="adopted_number"
                      value={this.state.newAdoptedDecision.adopted_number}
                      onChange={this.updateField}
                    />
                  </label>
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Document hotărâre:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocument}
                    removeDocument={this.removeDocument}
                    disabledDocuments={this.state.disabledDocument}
                    document={this.state.newAdoptedDecision.document}
                    documentFilename={
                      this.state.newAdoptedDecision.document_filename
                    }
                    type="application/pdf"
                  />
                </div>
                <div className="form-buttons">
                  <div className="admin-form__submit">
                    <button
                      type="submit"
                      className="submit-button general-button"
                    >
                      {this.state.newAdoptedDecision.id
                        ? 'Editează Hotarare'
                        : 'Adauga Hotarare'}
                    </button>
                  </div>
                  {Object.values(this.state.newAdoptedDecision) && (
                    <button
                      type="button"
                      className="general-button reset-button"
                      onClick={() => this.resetForm()}
                    >
                      Resetează câmpurile
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {this.state.existingAdoptedDecisions.length > 0 && (
            <div className="admin-standard-page__existing-items">
              <div className="card">
                <div className="existing-items">
                  <h2 className="existing-items-title label-title ">
                    Hotarari adoptate existente
                  </h2>
                  <div className="existing-items__content">
                    {this.state.existingAdoptedDecisions.map(
                      adoptedDecision => (
                        <div
                          className="existing-items__content-card"
                          key={adoptedDecision.id}
                        >
                          <div className="card-content provisions-title-content">
                            <h3 className="major-provisions-title">
                              {adoptedDecision.title}
                            </h3>
                          </div>
                          <div className="existing-items__actions-card">
                            <div
                              className="provisions-title-content"
                              onClick={() =>
                                this.setAdoptedDecisionForEdit(adoptedDecision)
                              }
                            >
                              <h4 className="action-title"> Editează</h4>
                            </div>

                            <div
                              className="provisions-title-content"
                              onClick={() =>
                                this.openDeleteDialog(adoptedDecision.id)
                              }
                            >
                              <h4 className="action-title delete-button">
                                {' '}
                                Șterge
                              </h4>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                  <DeletePUGCertificates
                    opened={this.state.deleteAdoptedDecision.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteti sigur ca doriti sa stergeti aceasta hotarare?"
                    aditionalText="Dupa stergere, aceasta hotarare nu va mai putea fi recuperata."
                  />
                </div>
                {this.state.pagination.last_page > 1 && (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pagination.last_page}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default AdminAdoptedDecisions;
