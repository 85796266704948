import { responseHandleService } from './ResponseHandleService';
import AuthenticationService from './AuthenticationService';
import axios from 'axios';

export const acquisitionsService = {
  getAll,
  search,
  deleteAcquisition
};

const requestOptions = {
  method: 'GET',
  headers: Object.assign(
    {},
    {
      Accept: 'application/json',
      'Content-type': 'application/json'
    }
  )
};

function getAll(pageNumber) {
  let url = `${process.env.REACT_APP_API_URL}/api/acquisitions/?page[number]=${
    pageNumber ? pageNumber : 1
  }`;

  return fetch(url, requestOptions).then(responseHandleService.handleResponse);
}

function search(searchAcquisitions, pageNumber) {
  let searchUrl = `${
    process.env.REACT_APP_API_URL
  }/api/acquisitions/search?title=${searchAcquisitions.title}&start_date=${
    searchAcquisitions.start_date
  }&end_date=${searchAcquisitions.end_date}&archiveYear=${
    searchAcquisitions.archiveYear
  }&page[number]=${pageNumber ? pageNumber : 1}`;

  return fetch(searchUrl, requestOptions).then(
    responseHandleService.handleResponse
  );
}

function deleteAcquisition(acquisitionId) {
  const requestOptionsDelete = {
    headers: {
      Accept: 'application/json'
    }
  };
  this.authentication = new AuthenticationService();

  if (this.authentication.loggedIn()) {
    requestOptionsDelete.headers['Authorization'] =
      'Bearer ' + this.authentication.getToken();
  }
  let url = `${
    process.env.REACT_APP_API_URL
  }/api/acquisitions/${acquisitionId}`;

  return axios.delete(url, requestOptionsDelete);
}
