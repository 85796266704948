import React from 'react';
import '../StandardPage/StandardPage.css';
import { balanceSheetsService } from '../../services/BalanceSheetsService';
import ReactPaginate from 'react-paginate';

class BalanceSheets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      balanceSheets: [],
      balanceSheetsPagination: {}
    };
  }

  componentWillMount() {
    balanceSheetsService
      .getAll()
      .then(jsonResponse => {
        this.setBalanceSheetsState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  setBalanceSheetsState = response => {
    let balanceSheets = { ...this.state.balanceSheets };
    let balanceSheetsPagination = { ...this.state.balanceSheetsPagination };
    balanceSheets = response.balance_sheets;
    balanceSheetsPagination = response.pagination;

    this.setState({ balanceSheets });
    this.setState({ balanceSheetsPagination });
  };

  handleBalanceSheetPageClick = selectedPage => {
    balanceSheetsService
      .getAll(selectedPage.selected + 1)
      .then(jsonResponse => {
        this.setBalanceSheetsState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  render() {
    return (
      <div className="standard">
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Interes Public > </h4>
            <h4 className="standard__generic">Bilanțuri Contabile</h4>
          </div>
          <h2 className="standard__main-title">Bilanțuri Contabile</h2>
        </div>
        <div className="standard__wrapper standard__wrapper-no-search">
          <div
            className={
              'standard__cards ' +
              (this.state.balanceSheets.length <= 2
                ? 'standard__projects-wrapper-few'
                : '')
            }
          >
            {this.state.balanceSheets.length > 0 ? (
              this.state.balanceSheets.map(balanceSheet => (
                <div
                  className="standard__individual-card  standard__card-local-link"
                  key={balanceSheet.id}
                >
                  <h3 className="standard__card-title">{balanceSheet.title}</h3>
                  <div className="mariage-publications-page__publication-visualize">
                    <h4
                      className="standard__see-project"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_API_URL}${
                            balanceSheet.document.url
                          }`
                        )
                      }
                    >
                      Vizualizează Document
                    </h4>
                    <img
                      src="/images/icons/file.svg"
                      className="standard__card-img"
                      alt="file"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_API_URL}${
                            balanceSheet.document.url
                          }`
                        )
                      }
                    />
                  </div>
                </div>
              ))
            ) : (
              <div className="standard__no-projects-box">
                <p className="standard__no-projects">
                  Informații in curs de actualizare.
                </p>
              </div>
            )}
          </div>
          {this.state.balanceSheetsPagination.last_page > 1 && (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.balanceSheetsPagination.last_page}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handleBalanceSheetPageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          )}
        </div>
      </div>
    );
  }
}

export default BalanceSheets;
