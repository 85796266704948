import React, { Component } from 'react';
import AuthenticationService from '../../../services/AuthenticationService';
import '../../../resources/AdminStandardPage.css';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import DeletePUGCertificates from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import { humanResourcesService } from '../../../services/HumanResourcesService';
import ReactPaginate from 'react-paginate';

class HumanResourcesAdmin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledDocumentContest: false,
      disabledDocumentSelection: false,
      disabledDocumentWrittenTest: false,
      disabledDocumentInterview: false,
      disabledDocumentFinalRaport: false,
      newAnnouncement: {
        title: '',
        description: '',
        document_contest: '',
        document_selection: '',
        document_written_test: '',
        document_interview: '',
        document_final_raport: ''
      },
      existingAnnouncements: [],
      deleteAnnouncement: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };

    this.authentication = new AuthenticationService();
  }

  componentWillMount() {
    humanResourcesService
      .getAll()
      .then(jsonResponse => {
        this.setHumanResourcesState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  handlePageClick = selectedPage => {
    humanResourcesService
      .getAll(selectedPage.selected + 1)
      .then(jsonResponse => {
        this.setHumanResourcesState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  setHumanResourcesState = response => {
    let existingAnnouncements = { ...this.state.existingAnnouncements };
    let pagination = { ...this.state.pagination };
    existingAnnouncements = response.human_resources;
    pagination = response.pagination;

    this.setState({ existingAnnouncements });
    this.setState({ pagination });
  };

  updateField = event => {
    const field = event.target.name;
    const newAnnouncement = this.state.newAnnouncement;
    newAnnouncement[field] = event.target.value;

    this.setState({
      newAnnouncement
    });
  };

  onDropDocumentContest = documents => {
    let newAnnouncement = { ...this.state.newAnnouncement };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newAnnouncement.document_contest = documents[0];

      self.setState({ newAnnouncement });
      self.setState({ disabledDocumentContest: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocumentContest = () => {
    let newAnnouncement = { ...this.state.newAnnouncement };
    newAnnouncement.document_contest = null;

    this.setState({ newAnnouncement });
    this.setState({ disabledDocumentContest: false });
  };

  onDropDocumentSelection = documents => {
    let newAnnouncement = { ...this.state.newAnnouncement };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newAnnouncement.document_selection = documents[0];

      self.setState({ newAnnouncement });
      self.setState({ disabledDocumentSelection: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocumentSelection = () => {
    let newAnnouncement = { ...this.state.newAnnouncement };
    newAnnouncement.document_selection = null;

    this.setState({ newAnnouncement });
    this.setState({ disabledDocumentSelection: false });
  };

  onDropDocumentWrittenTest = documents => {
    let newAnnouncement = { ...this.state.newAnnouncement };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newAnnouncement.document_written_test = documents[0];

      self.setState({ newAnnouncement });
      self.setState({ disabledDocumentWrittenTest: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocumentWrittenTest = () => {
    let newAnnouncement = { ...this.state.newAnnouncement };
    newAnnouncement.document_written_test = null;

    this.setState({ newAnnouncement });
    this.setState({ disabledDocumentWrittenTest: false });
  };

  onDropDocumentInterview = documents => {
    let newAnnouncement = { ...this.state.newAnnouncement };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newAnnouncement.document_interview = documents[0];

      self.setState({ newAnnouncement });
      self.setState({ disabledDocumentInterview: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocumentInterview = () => {
    let newAnnouncement = { ...this.state.newAnnouncement };
    newAnnouncement.document_interview = null;

    this.setState({ newAnnouncement });
    this.setState({ disabledDocumentInterview: false });
  };

  onDropDocumentFinalRaport = documents => {
    let newAnnouncement = { ...this.state.newAnnouncement };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newAnnouncement.document_final_raport = documents[0];

      self.setState({ newAnnouncement });
      self.setState({ disabledDocumentFinalRaport: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocumentFinalRaport = () => {
    let newAnnouncement = { ...this.state.newAnnouncement };
    newAnnouncement.document_final_raport = null;

    this.setState({ newAnnouncement });
    this.setState({ disabledDocumentFinalRaport: false });
  };

  resetForm = () => {
    let newAnnouncement = { ...this.state.newAnnouncement };
    newAnnouncement = {
      title: '',
      description: '',
      document_contest: '',
      document_selection: '',
      document_written_test: '',
      document_interview: '',
      document_final_raport: ''
    };

    this.setState({ newAnnouncement });
    this.setState({ disabledDocumentContest: false });
    this.setState({ disabledDocumentSelection: false });
    this.setState({ disabledDocumentWrittenTest: false });
    this.setState({ disabledDocumentInterview: false });
    this.setState({ disabledDocumentFinalRaport: false });
  };

  handleFormSubmit = event => {
    event.preventDefault();
    const currentId = this.state.newAnnouncement.id;

    const headers = {
      Accept: 'application/json'
    };

    if (this.authentication.loggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.authentication.getToken();
    }

    let url = `${process.env.REACT_APP_API_URL}/api/human_resources/`;
    let callMethod = 'POST';

    if (currentId) {
      url = `${process.env.REACT_APP_API_URL}/api/human_resources/${
        this.state.newAnnouncement.id
      }`;
      callMethod = 'PUT';
    }

    const formattedData = this.buildFormData();

    fetch(url, {
      method: callMethod,
      body: formattedData,
      headers
    })
      .then(this.handleErrors)
      .then(response => response.json())
      .then(jsonResponse => {
        this.resetForm();

        let existingAnnouncements = this.state.existingAnnouncements.slice();

        let index = existingAnnouncements.findIndex(
          existingAnnouncement => existingAnnouncement.id === jsonResponse.id
        );

        if (index !== -1) {
          existingAnnouncements[index] = jsonResponse;
        } else {
          existingAnnouncements.push(jsonResponse);
        }

        this.setState({ existingAnnouncements });

        if (currentId) {
          this._notificationSystem.addNotification({
            message: 'Anuntul a fost editat cu success.',
            level: 'success',
            position: 'tc'
          });
        } else {
          this._notificationSystem.addNotification({
            message: 'Anuntul a fost adaugat cu success.',
            level: 'success',
            position: 'tc'
          });
        }
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  buildFormData() {
    let formData = new FormData();
    formData.append('human_resource[title]', this.state.newAnnouncement.title);
    formData.append(
      'human_resource[description]',
      this.state.newAnnouncement.description
    );

    if (this.state.newAnnouncement.document_contest) {
      let document = this.state.newAnnouncement.document_contest;
      formData.append(
        `human_resource[document_contest]`,
        document,
        document.name
      );
    }

    if (this.state.newAnnouncement.document_selection) {
      let document = this.state.newAnnouncement.document_selection;
      formData.append(
        `human_resource[document_selection]`,
        document,
        document.name
      );
    }

    if (this.state.newAnnouncement.document_written_test) {
      let document = this.state.newAnnouncement.document_written_test;
      formData.append(
        `human_resource[document_written_test]`,
        document,
        document.name
      );
    }

    if (this.state.newAnnouncement.document_interview) {
      let document = this.state.newAnnouncement.document_interview;
      formData.append(
        `human_resource[document_interview]`,
        document,
        document.name
      );
    }

    if (this.state.newAnnouncement.document_final_raport) {
      let document = this.state.newAnnouncement.document_final_raport;
      formData.append(
        `human_resource[document_final_raport]`,
        document,
        document.name
      );
    }

    return formData;
  }

  setAnnouncementForEdit = newProject => {
    let newAnnouncement = { ...this.state.newAnnouncement };
    newAnnouncement = Object.assign({}, newProject);
    this.setState({ newAnnouncement });

    if (newProject.document_contest.url) {
      this.setState({ disabledDocumentContest: true });
    }

    if (newProject.document_selection.url) {
      this.setState({ disabledDocumentSelection: true });
    }

    if (newProject.document_written_test.url) {
      this.setState({ disabledDocumentWrittenTest: true });
    }

    if (newProject.document_interview.url) {
      this.setState({ disabledDocumentInterview: true });
    }

    if (newProject.document_final_raport.url) {
      this.setState({ disabledDocumentFinalRaport: true });
    }
  };

  openDeleteDialog = id => {
    let deleteAnnouncement = { ...this.state.deleteAnnouncement };
    deleteAnnouncement = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteAnnouncement });
  };

  closeDeleteDialog = () => {
    let deleteAnnouncement = { ...this.state.deleteAnnouncement };
    deleteAnnouncement = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteAnnouncement });
  };

  handleDelete = () => {
    humanResourcesService
      .deleteHumanResource(this.state.deleteAnnouncement.deleteId)
      .then(() => {
        let existingAnnouncements = this.state.existingAnnouncements.slice();
        existingAnnouncements = existingAnnouncements.filter(
          announcement =>
            announcement.id !== this.state.deleteAnnouncement.deleteId
        );
        this.setState({ existingAnnouncements });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Anuntul a fost stears cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  handleErrors = response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  };

  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-standard-page__title">
          <h3 className="admin-page__profile-title profile-title-specific">
            Resurse Umane
          </h3>
          <h1 className="admin-switch-title default-title">Anunturi</h1>
        </div>
        <div className="title-line" />
        <div className="admin-standard-page__content">
          <div className="admin-standard-page__create-project">
            <div className="card">
              <form className="admin-form" onSubmit={this.handleFormSubmit}>
                <h2 className="admin-form__title">Adauga un anunt nou</h2>

                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">Titlu*</h4>
                  <input
                    required
                    className="general-field title-field"
                    type="text"
                    name="title"
                    onChange={this.updateField}
                    value={this.state.newAnnouncement.title}
                  />
                  <h4 className="label-title label-title-provisions">
                    Descriere anunt*
                  </h4>
                  <textarea
                    required
                    rows="5"
                    className="general-field title-field"
                    type="text"
                    name="description"
                    onChange={this.updateField}
                    value={this.state.newAnnouncement.description}
                  />
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Anunt concurs:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocumentContest}
                    removeDocument={this.removeDocumentContest}
                    disabledDocuments={this.state.disabledDocumentContest}
                    document={this.state.newAnnouncement.document_contest}
                    documentFilename={
                      this.state.newAnnouncement.document_contest_filename
                    }
                    type="application/pdf"
                  />
                  <h4 className="label-title label-title-provisions">
                    Selectie dosare:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocumentSelection}
                    removeDocument={this.removeDocumentSelection}
                    disabledDocuments={this.state.disabledDocumentSelection}
                    document={this.state.newAnnouncement.document_selection}
                    documentFilename={
                      this.state.newAnnouncement.document_selection_filename
                    }
                    type="application/pdf"
                  />
                  <h4 className="label-title label-title-provisions">
                    Rezultat proba scrisa:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocumentWrittenTest}
                    removeDocument={this.removeDocumentWrittenTest}
                    disabledDocuments={this.state.disabledDocumentWrittenTest}
                    document={this.state.newAnnouncement.document_written_test}
                    documentFilename={
                      this.state.newAnnouncement.document_written_test_filename
                    }
                    type="application/pdf"
                  />
                  <h4 className="label-title label-title-provisions">
                    Rezultat interviu:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocumentInterview}
                    removeDocument={this.removeDocumentInterview}
                    disabledDocuments={this.state.disabledDocumentInterview}
                    document={this.state.newAnnouncement.document_interview}
                    documentFilename={
                      this.state.newAnnouncement.document_interview_filename
                    }
                    type="application/pdf"
                  />
                  <h4 className="label-title label-title-provisions">
                    Raport final:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocumentFinalRaport}
                    removeDocument={this.removeDocumentFinalRaport}
                    disabledDocuments={this.state.disabledDocumentFinalRaport}
                    document={this.state.newAnnouncement.document_final_raport}
                    documentFilename={
                      this.state.newAnnouncement.document_final_raport_filename
                    }
                    type="application/pdf"
                  />
                </div>
                <div className="form-buttons">
                  <div className="admin-form__submit">
                    <button
                      type="submit"
                      className="submit-button general-button"
                    >
                      {this.state.newAnnouncement.id
                        ? 'Editează Anunt'
                        : 'Adaugă Anunt'}
                    </button>
                  </div>
                  {Object.values(this.state.newAnnouncement) && (
                    <button
                      type="button"
                      className="general-button reset-button"
                      onClick={() => this.resetForm()}
                    >
                      Resetează câmpurile
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {this.state.existingAnnouncements.length > 0 && (
            <div className="admin-standard-page__existing-items">
              <div className="card">
                <div className="existing-items">
                  <h2 className="existing-items__title label-title">
                    Anunturi existente
                  </h2>
                  <div className="existing-items__content">
                    {this.state.existingAnnouncements.map(announcement => (
                      <div
                        className="existing-items__content-card"
                        key={announcement.id}
                      >
                        <div className="card-content provisions-title-content">
                          <h3 className="major-provisions-title">
                            {announcement.title}
                          </h3>
                        </div>
                        <div className="existing-items__actions-card">
                          <div
                            className="provisions-title-content"
                            onClick={() =>
                              this.setAnnouncementForEdit(announcement)
                            }
                          >
                            <h4 className="action-title"> Editează</h4>
                          </div>

                          <div
                            className="provisions-title-content"
                            onClick={() =>
                              this.openDeleteDialog(announcement.id)
                            }
                          >
                            <h4 className="action-title delete-button">
                              {' '}
                              Șterge
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <DeletePUGCertificates
                    opened={this.state.deleteAnnouncement.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteti sigur ca doriti sa stergeti acest document?"
                    aditionalText="Dupa stergere, documentul nu va mai putea fi recuperat."
                  />
                </div>
                {this.state.pagination.last_page > 1 && (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pagination.last_page}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default HumanResourcesAdmin;
