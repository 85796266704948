import React, { Component } from 'react';
import AuthenticationService from '../../../services/AuthenticationService';
import '../../../resources/AdminStandardPage.css';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import DeletePUGCertificates from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import { socialAidService } from '../../../services/SocialAidService';
import ReactPaginate from 'react-paginate';

class AdminSocialAid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledDocument: false,
      newSocialAid: {
        title: '',
        document: '',
        date: ''
      },
      existingSocialAids: [],
      deleteSocialAid: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };

    this.authentication = new AuthenticationService();
  }

  componentWillMount() {
    socialAidService
      .getAll()
      .then(jsonResponse => {
        this.setSocialAidState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  handlePageClick = selectedPage => {
    socialAidService
      .getAll(selectedPage.selected + 1)
      .then(jsonResponse => {
        this.setSocialAidState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  setSocialAidState = response => {
    let existingSocialAids = { ...this.state.existingSocialAids };
    let pagination = { ...this.state.pagination };
    existingSocialAids = response.social_aids;
    pagination = response.pagination;

    this.setState({ existingSocialAids });
    this.setState({ pagination });
  };

  updateField = event => {
    const field = event.target.name;
    const newSocialAid = this.state.newSocialAid;
    newSocialAid[field] = event.target.value;

    this.setState({
      newSocialAid
    });
  };

  onDropDocument = documents => {
    let newSocialAid = { ...this.state.newSocialAid };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newSocialAid.document = documents[0];

      self.setState({ newSocialAid });
      self.setState({ disabledDocument: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let newSocialAid = { ...this.state.newSocialAid };
    newSocialAid.document = null;

    this.setState({ newSocialAid });
    this.setState({ disabledDocument: false });
  };

  resetForm = () => {
    let newSocialAid = { ...this.state.newSocialAid };
    newSocialAid = {
      title: '',
      document: ''
    };

    this.setState({ newSocialAid });
    this.setState({ disabledDocument: false });
  };

  handleFormSubmit = event => {
    event.preventDefault();
    const currentId = this.state.newSocialAid.id;

    const headers = {
      Accept: 'application/json'
    };

    if (this.authentication.loggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.authentication.getToken();
    }

    let url = `${process.env.REACT_APP_API_URL}/api/social_aids/`;
    let callMethod = 'POST';

    if (currentId) {
      url = `${process.env.REACT_APP_API_URL}/api/social_aids/${
        this.state.newSocialAid.id
      }`;
      callMethod = 'PUT';
    }

    const formattedData = this.buildFormData(this.state.newSocialAid);

    fetch(url, {
      method: callMethod,
      body: formattedData,
      headers
    })
      .then(this.handleErrors)
      .then(response => response.json())
      .then(jsonResponse => {
        this.resetForm();

        let existingSocialAids = this.state.existingSocialAids.slice();

        let index = existingSocialAids.findIndex(
          certificate => certificate.id === jsonResponse.id
        );

        if (index !== -1) {
          existingSocialAids[index] = jsonResponse;
        } else {
          existingSocialAids.push(jsonResponse);
        }

        this.setState({ existingSocialAids });

        if (currentId) {
          this._notificationSystem.addNotification({
            message: 'Documentul a fost editat cu success.',
            level: 'success',
            position: 'tc'
          });
        } else {
          this._notificationSystem.addNotification({
            message: 'Documentul a fost adaugat cu success.',
            level: 'success',
            position: 'tc'
          });
        }
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  buildFormData() {
    let formData = new FormData();
    formData.append('social_aid[title]', this.state.newSocialAid.title);
    formData.append('social_aid[add_date]', this.state.newSocialAid.date);

    if (this.state.newSocialAid.document) {
      let document = this.state.newSocialAid.document;

      if (document.url) {
        formData.append(`social_aid[document]`, document.url);
      } else {
        formData.append(`social_aid[document]`, document, document.name);
      }
    }

    return formData;
  }

  setSocialAitForEdit = socialAid => {
    let newSocialAid = { ...this.state.newSocialAid };
    newSocialAid = Object.assign({}, socialAid);
    this.setState({ newSocialAid });
    this.setState({ disabledDocuments: true });
  };

  openDeleteDialog = id => {
    let deleteSocialAid = { ...this.state.deleteSocialAid };
    deleteSocialAid = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteSocialAid });
  };

  closeDeleteDialog = () => {
    let deleteSocialAid = { ...this.state.deleteSocialAid };
    deleteSocialAid = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteSocialAid });
  };

  handleDelete = () => {
    socialAidService
      .deleteSocialAid(this.state.deleteSocialAid.deleteId)
      .then(() => {
        let existingSocialAids = this.state.existingSocialAids.slice();
        existingSocialAids = existingSocialAids.filter(
          certificate => certificate.id !== this.state.deleteSocialAid.deleteId
        );
        this.setState({ existingSocialAids });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Documentul a fost sters cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  handleErrors = response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  };

  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-standard-page__title">
          <h3 className="admin-page__profile-title profile-title-specific">
            Asistenta Sociala
          </h3>
          <h1 className="admin-switch-title default-title">Ajutor Social</h1>
        </div>
        <div className="title-line" />
        <div className="admin-standard-page__content">
          <div className="admin-standard-page__create-project">
            <div className="card">
              <form className="admin-form" onSubmit={this.handleFormSubmit}>
                <h2 className="admin-form__title">
                  Tabel Nominal pentru Ajutor Social
                </h2>

                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Titlu document*
                  </h4>
                  <input
                    required
                    className="general-field title-field"
                    type="text"
                    name="title"
                    onChange={this.updateField}
                    value={this.state.newSocialAid.title}
                  />
                </div>
                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">Data*</h4>
                  <input
                    required
                    className="general-field title-field"
                    type="date"
                    name="date"
                    onChange={this.updateField}
                    value={this.state.newSocialAid.date}
                  />
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Document Ajutor Social:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocument}
                    removeDocument={this.removeDocument}
                    disabledDocuments={this.state.disabledDocument}
                    document={this.state.newSocialAid.document}
                    documentFilename={this.state.newSocialAid.document_filename}
                    type="application/pdf"
                  />
                </div>
                <div className="form-buttons">
                  <div className="admin-form__submit">
                    <button
                      type="submit"
                      className="submit-button general-button"
                    >
                      {this.state.newSocialAid.id
                        ? 'Editează Ajutor Social'
                        : 'Adaugă Ajutor Social'}
                    </button>
                  </div>
                  {Object.values(this.state.newSocialAid) && (
                    <button
                      type="button"
                      className="general-button reset-button"
                      onClick={() => this.resetForm()}
                    >
                      Resetează câmpurile
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {this.state.existingSocialAids.length > 0 && (
            <div className="admin-standard-page__existing-items">
              <div className="card">
                <div className="existing-items">
                  <h2 className="existing-items__title label-title">
                    Procese verbale existente
                  </h2>
                  <div className="existing-items__content">
                    {this.state.existingSocialAids.map(socialAid => (
                      <div
                        className="existing-items__content-card"
                        key={socialAid.id}
                      >
                        <div className="card-content provisions-title-content">
                          <h3 className="major-provisions-title">
                            {socialAid.title}
                          </h3>
                        </div>
                        <div className="existing-items__actions-card">
                          <div
                            className="provisions-title-content"
                            onClick={() => this.setSocialAidForEdit(socialAid)}
                          >
                            <h4 className="action-title"> Editează</h4>
                          </div>

                          <div
                            className="provisions-title-content"
                            onClick={() => this.openDeleteDialog(socialAid.id)}
                          >
                            <h4 className="action-title delete-button">
                              {' '}
                              Șterge
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <DeletePUGCertificates
                    opened={this.state.deleteSocialAid.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteti sigur ca doriti sa stergeti aceast document?"
                    aditionalText="Dupa stergere, documentul nu va mai putea fi recuperat."
                  />
                </div>
                {this.state.pagination.last_page > 1 && (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pagination.last_page}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default AdminSocialAid;
