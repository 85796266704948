import React, { Component } from 'react';
import bannerImages from './bannerImages.json';
import './Homepage.css';
import { Link } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import MetaTags from 'react-meta-tags';
import { eventService } from '../../services/EventService';
import { announcementsService } from '../../services/AnnouncementsService';
import { ongoingProjectsService } from '../../services/OngoingProjectsService';
import ImageNextGen from '../../components/ImageNextGen/ImageNextGen';
import { googleVerificationService } from '../../services/GoogleVerificationService';
import Moment from 'react-moment';

class Homepage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bannerImages: bannerImages,
      lastAnnouncement: {},
      lastEvent: {},
      lastProject: {}
    };
  }

  componentWillMount() {
    eventService
      .lastEvent()
      .then(jsonResponse => {
        if (jsonResponse.data.event.length) {
          this.setState({ lastEvent: jsonResponse.data.event[0] });
        }
      })
      .catch(error => console.log(error));

    announcementsService
      .getLast()
      .then(jsonResponse => {
        if (jsonResponse.data.announcement.length) {
          this.setState({
            lastAnnouncement: jsonResponse.data.announcement[0]
          });
        }
      })
      .catch(error => console.log(error));

    ongoingProjectsService
      .lastProject()
      .then(jsonResponse => {
        if (jsonResponse.data.project.length) {
          this.setState({ lastProject: jsonResponse.data.project[0] });
        }
      })
      .catch(error => console.log(error));
  }

  openUsefullLinks = url => {
    window.open(url, '_blank');
  };

  render() {
    return (
      <div className="homepage">
        <MetaTags>
          <title>Primăria Frătăuții Vechi</title>
          <meta
            name="description"
            content="Bine ati venit pe pagina oficiala a primariei Frătăuții Vechi."
          />
          <meta
            name="keywords"
            content="primaria Frătăuții Vechi, Frătăuții Vechi, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire"
          />
          <meta property="og:title" content="Primaria Frătăuții Vechi" />
          <meta
            property="og:url"
            content="https://www.primaria-fratautiivechi.ro/"
          />
        </MetaTags>
        <div className="homepage__banner">
          <ImageNextGen
            class="homepage__banner-img"
            imageUrl="/images/bannerImages/fratautii_vechi_8.jpg"
            imageUrlWebp="/images/bannerImages/fratautii_vechi_8.webp"
            imageUrlJpeg2000="/images/bannerImages/fratautii_vechi_8.jp2"
          />
          {Object.keys(this.state.lastAnnouncement).length ? (
            <div className="homepage__banner-info">
              <div className="homepage__banner-date">
                <h5 className="homepage__date">
                  <Moment
                    format="DD-MM-YYYY"
                    date={this.state.lastAnnouncement.created_at}
                  />
                </h5>
              </div>
              <div className="homepage__banner-description">
                <h3 className="homepage__banner-title">
                  {this.state.lastAnnouncement.title}
                </h3>
                <h4 className="homepage__banner-subtitle">
                  {this.state.lastAnnouncement.description}
                </h4>
              </div>
              <div className="homepage__read-more-box">
                <Link className="homepage__middle-link" to="/anunturi">
                  <h5 className="homepage__read-more">Citeste mai multe</h5>
                </Link>
              </div>
            </div>
          ) : (
            undefined
          )}
        </div>
        <div className="homepage__middle-section">
          <h3 className="homepage__monitor-title">Monitorul Oficial Local</h3>
          <div className="homepage__middle-section-second">
            <div className="homepage__card">
              <Link
                className="homepage__middle-link"
                to="/legislation/statut_uat_2022.pdf"
                target="_blank"
              >
                <img
                  src="/images/icons/city-hall.svg"
                  className="homepage__middle-icon"
                  alt="city-hall"
                />
                <h4 className="homepage__middle-section-subtitle">
                  Statutul Unității Administrativ-Teritoriale
                </h4>
              </Link>
            </div>

            <div className="homepage__card">
              <Link
                className="homepage__middle-link"
                to="/regulament-organizare"
              >
                <img
                  src="/images/icons/city-hall.svg"
                  className="homepage__middle-icon"
                  alt="city-hall"
                />
                <h4 className="homepage__middle-section-subtitle">
                  Regulamentele privind Procedurile Administrative
                </h4>
              </Link>
            </div>

            <div className="homepage__card">
              <Link className="homepage__middle-link" to="/hotarari-adoptate">
                <img
                  src="/images/icons/city-hall.svg"
                  className="homepage__middle-icon"
                  alt="city-hall"
                />
                <h4 className="homepage__middle-section-subtitle">
                  Hotărârile Autorității Deliberative
                </h4>
              </Link>
            </div>

            <div className="homepage__card">
              <Link
                className="homepage__middle-link"
                to="/dispozitiile-primarului"
              >
                <img
                  src="/images/icons/city-hall.svg"
                  className="homepage__middle-icon"
                  alt="city-hall"
                />
                <h4 className="homepage__middle-section-subtitle">
                  Dispozițiile Autorității Executive
                </h4>
              </Link>
            </div>

            <div className="homepage__card">
              <Link
                className="homepage__middle-link"
                to="/informatii-financiare"
              >
                <img
                  src="/images/icons/city-hall.svg"
                  className="homepage__middle-icon"
                  alt="city-hall"
                />
                <h4 className="homepage__middle-section-subtitle">
                  Documente și Informații Financiare
                </h4>
              </Link>
            </div>

            <div className="homepage__card">
              <Link
                className="homepage__middle-link"
                to="/documente-interes-public"
              >
                <img
                  src="/images/icons/city-hall.svg"
                  className="homepage__middle-icon"
                  alt="city-hall"
                />
                <h4 className="homepage__middle-section-subtitle">
                  Documente de interes public
                </h4>
              </Link>
            </div>
          </div>
        </div>
        <div className="homepage__taxes-section">
          <h3 className="homepage__taxes">
            {' '}
            Pentru plata impozitelor, taxelor locale și amenzilor, Primăria vă
            pune la dispoziție{' '}
            <a
              className="homepage__link standard__taxes-link"
              href="https://www.ghiseul.ro/ghiseul/public/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              portalul online Ghiseul.ro
            </a>
            . Acest sistem de plată electronică este disponibil atât pentru
            persoanele fizice, cât și pentru persoanele juridice.
          </h3>
          <h3 className="homepage__taxes">
            {' '}
            Pentru plata impozitelor și taxelor locale prin intermediul
            Ghiseul.ro nu se percep comisioane pentru tranzacțiile efectuate.
          </h3>
        </div>
        <div className="homepage__events-and-projects">
          <div className="homepage__general-news">
            <h2 className="homepage__general-title">Evenimente</h2>
            {Object.keys(this.state.lastEvent).length ? (
              <div>
                <h3 className="homepage__general-subtitle">
                  Cel mai recent eveniment din localitatea noastră
                </h3>
                <div className="homepage__general-pic-box">
                  <img
                    src={
                      this.state.lastEvent.picture &&
                      this.state.lastEvent.picture.url
                        ? `${process.env.REACT_APP_API_URL}/${this.state.lastEvent.picture.url}`
                        : ''
                    }
                    className="homepage__general-news-pic"
                    alt="event-pic"
                  />
                  <div className="homepage__news-date-box">
                    <h4 className="homepage__news-date">
                      <Moment
                        format="DD-MM-YYYY"
                        date={this.state.lastEvent.start_date}
                      />
                    </h4>
                  </div>
                </div>
                <h3 className="homepage__general-name">
                  {this.state.lastEvent.title}
                </h3>
                <h4 className="homepage__general-description">
                  {this.state.lastEvent.description}
                </h4>
                <button className="homepage__general-button">
                  <h5 className="homepage__general-see-more">Vezi eveniment</h5>
                </button>
              </div>
            ) : (
              undefined
            )}
          </div>
          <div className="homepage__general-news homepage__project">
            <h2 className="homepage__general-title homepage__project-main-title">
              Proiecte
            </h2>
            <h3 className="homepage__general-subtitle homepage__project-subtitle">
              Cel mai recent proiect din localitatea noastră
            </h3>
            {Object.keys(this.state.lastProject).length ? (
              <div>
                <div className="homepage__general-pic-box">
                  <img
                    src={
                      this.state.lastProject.picture &&
                      this.state.lastProject.picture.url
                        ? `${process.env.REACT_APP_API_URL}/${this.state.lastProject.picture.url}`
                        : ''
                    }
                    className="homepage__general-news-pic"
                    alt="project-pic"
                  />
                  <div className="homepage__news-date-box homepage__project-date-box">
                    <h4 className="homepage__news-date homepage__project-date">
                      <Moment
                        format="DD-MM-YYYY"
                        date={this.state.lastProject.start_date}
                      />
                    </h4>
                  </div>
                </div>
                <h3 className="homepage__general-name homepage__project-name">
                  {this.state.lastProject.title}
                </h3>
                <h4 className="homepage__general-description homepage__project-description">
                  {this.state.lastProject.description}
                </h4>
                <button className="homepage__general-button">
                  <h5 className="homepage__general-see-more">Vezi proiect</h5>
                </button>
              </div>
            ) : (
              undefined
            )}
          </div>
        </div>
        <div className="homepage__usefull-links">
          <h2>Linkuri Externe</h2>
          <div className="homepage__usefull-links-list">
            <div className="homepage__card homepage__card-link">
              <Link
                to="https://sgg.gov.ro/new/guvernare-transparenta-deschisa-si-participativa-standardizare-armonizare-dialog-imbunatatit-cod-sipoca-35/"
                target="_blank"
              >
                <img
                  src="/images/icons/unlink.svg"
                  className="homepage__link-icon"
                  alt="link-icon"
                />
                <h3 className="homepage__link-title">
                  Guvernare transparentă, deschisă și participativă
                </h3>
              </Link>
            </div>
            <div className="homepage__card homepage__card-link">
              <Link to="/documents/CoronaVirusPrevenire.pdf">
                <img
                  src="/images/icons/unlink.svg"
                  className="homepage__link-icon"
                  alt="link-icon"
                />
                <h3 className="homepage__link-title">
                  Masuri de protectie impotriva CoronaVirus
                </h3>
              </Link>
            </div>
          </div>
        </div>
        <CookieConsent
          location="bottom"
          flipButtons
          buttonText="Accept Cookies"
          declineButtonText="Refuz Cookies"
          style={{ background: '#2B373B' }}
          buttonStyle={{ color: '#4e503b', fontSize: '13px' }}
          expires={150}
        >
          Acest site folosește cookies. Navigând în continuare vă exprimați
          acordul asupra folosirii cookie-urilor.{' '}
        </CookieConsent>
      </div>
    );
  }
}

export default Homepage;
