import React from 'react';
import generalForms from './GeneralForms.json';
import '../StandardPage/StandardPage.css';
import { Link } from 'react-router-dom';

class NewsLetter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      generalForms: generalForms
    };
  }
  scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };
  render() {
    return (
      <div className="standard">
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Interes Public > </h4>
            <h4 className="standard__generic">Formulare </h4>
          </div>
          <h2 className="standard__main-title">Formulare Tip</h2>
        </div>
        <div className="standard__wrapper">
          <h3 className="standard__submitted-title">
            Formulare destinate cetățenilor pentru accesarea serviciilor
            primăriei.
          </h3>
          <div className="standard__cards">
            {this.state.generalForms.map(generalFormsItem => (
              <div
                className="standard__individual-card  standard__card-local-link"
                key={generalFormsItem.id}
              >
                <h3 className="standard__card-title">
                  {generalFormsItem.title}
                </h3>
                <div className="mariage-publications-page__publication-visualize">
                  {generalFormsItem.id < 12 ? (
                    <Link
                      onClick={() => this.scrollToTop()}
                      to={generalFormsItem.url}
                      className="standard__see-project"
                    >
                      Vizualizeaza formular
                    </Link>
                  ) : (
                    <h4
                      onClick={() => window.open(generalFormsItem.url)}
                      className="standard__see-project"
                    >
                      Vizualizeaza formular
                    </h4>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default NewsLetter;
