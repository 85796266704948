import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';
import { reportsAndStudies } from '../../services/ReportsStudiesService';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import archiveJson from '../../resources/archiveYears.json';
import ReactPaginate from 'react-paginate';

class ActivityReports extends Component {
  constructor(props) {
    super(props);
    this.state = {
      documents: [],
      pagination: '',
      searchReport: {
        title: '',
        archiveYear: 2024
      },
      archiveYears: archiveJson
    };
  }
  componentWillMount() {
    reportsAndStudies
      .getAll()
      .then(jsonResponse => {
        console.log(jsonResponse);
        this.setActivityReports(jsonResponse);
      })
      .catch(error => console.log(error));
  }
  handlePageClick = selectedPage => {
    this.search(selectedPage.selected + 1);
  };
  setActivityReports = response => {
    let documents = { ...this.state.documents };
    let pagination = { ...this.state.pagination };
    documents = response.reports_and_studies;
    pagination = response.pagination;

    this.setState({ documents });
    this.setState({ pagination });

    console.log(this.state.documents);
  };
  updateField = event => {
    const field = event.target.name;
    const searchReport = this.state.searchReport;
    searchReport[field] = event.target.value;

    this.setState(
      {
        searchReport
      },
      () => {
        if (field === 'archiveYear') {
          this.search();
        }
      }
    );
  };

  search = (pageNumber = 1) => {
    reportsAndStudies
      .search(this.state.searchReport, pageNumber)
      .then(jsonResponse => {
        this.setActivityReports(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Rapoarte de Activitate</title>
          <meta
            name="description"
            content="Rapoartele de Activitate ale primariei Frătăuții Vechi, aici fiind prezentate rapoartele de activitate ale primarului, viceprimarului, consiliului local si a consilierilor."
          />
          <meta
            name="keywords"
            content="primaria Frătăuții Vechi, Frătăuții Vechi, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, alocatie, anunturi"
          />
          <meta property="og:title" content="Rapoarte de Activitate" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Prezentare > </h4>
            <h4 className="standard__generic">Rapoarte si studii</h4>
          </div>
          <h2 className="standard__main-title">Rapoarte de activitate</h2>
        </div>
        <div className="standard__wrapper">
          <div className="standard__input-box standard__mp-input-box">
            <input
              className="standard__input standard__mp-input"
              label="Cautare Document"
              placeholder="Căutare Document"
              name="title"
              ovalue={this.state.searchReport.title}
              onChange={this.updateField}
            />
            <img
              src="/images/icons/search.svg"
              className="standard__search-icon mariage-publications__search-icon"
              alt="search-icon"
              onClick={() => this.search()}
            />
            <div className="standard__input-box mariage-publications__input-box lead-page__input-box">
              <FormControl>
                <NativeSelect
                  value={this.state.searchReport.archiveYear}
                  onChange={this.updateField}
                  inputProps={{
                    name: 'archiveYear'
                  }}
                >
                  {this.state.archiveYears.map(archive => (
                    <option value={archive.value}>{archive.name}</option>
                  ))}
                </NativeSelect>
              </FormControl>
            </div>
          </div>
          <div className="standard__cards standard__projects-wrapper-few">
            {this.state.documents && this.state.documents.length > 0 ? (
              this.state.documents.map(document => (
                <div
                  key={document.id}
                  className="standard__individual-card sa-title"
                >
                  <h3 className="standard__card-title">{document.title}</h3>
                  <h5 className="standard__card-date">{document.add_date}</h5>
                  <h4
                    className="standard__see-project"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${
                          document.document.url
                        }`
                      )
                    }
                  >
                    Vizualizează Document
                  </h4>
                  <img
                    src="/images/icons/file.svg"
                    className="standard__card-img"
                    alt="file"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${
                          document.document.url
                        }`
                      )
                    }
                  />
                </div>
              ))
            ) : (
              <div className="standard__no-projects-box">
                <p className="standard__no-projects">
                  Informații in curs de actualizare.
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="standard__no-projects-box">
          <p className="standard__no-projects">
            Informații in curs de actualizare.
          </p>
          {this.state.pagination.last_page > 1 && (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.pagination.last_page}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ActivityReports;
