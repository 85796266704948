import React, { Component } from 'react';
import { bool, func, object } from 'prop-types';
import './EventModal.css';
import '../../resources/StandardPage.css';
import Modal from 'react-modal';

const customStyles = {
  overlay: {
    zIndex: 12,
    backgroundColor: 'rgba(13, 8, 32, 0.9)',
    overflow: 'scroll'
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: 'auto'
  }
};

class EventModal extends Component {
  render() {
    return (
      <Modal
        isOpen={this.props.modalIsOpen}
        ariaHideApp={false}
        onRequestClose={this.props.closeModal}
        style={customStyles}
        contentLabel="Event Modal"
      >
        <div className="event-modal">
          <div className="event-modal__right-section">
            <img
              src={`${process.env.REACT_APP_API_URL}${
                this.props.eventObject.picture.url
              }`}
              alt="Event"
              className="event-modal__image"
            />
            <img
              onClick={() => this.props.closeModal()}
              src="/images/icons/close-icon-black.png"
              alt="close Icon"
              className="event-modal__close-modal"
            />
          </div>
          <div className="event-modal__content">
            <h3 className="event-modal__title">
              {this.props.eventObject.title}
            </h3>
            <div
              className={`event-modal__timeline-status--${
                this.props.eventObject.status
              }`}
            >
              <h4 className="event-modal__timeline">
                {this.props.eventObject.start_date} -{' '}
                {this.props.eventObject.end_date}
              </h4>
            </div>
            <h4 className="event-modal__description">
              {this.props.eventObject.description}
            </h4>
          </div>
        </div>
      </Modal>
    );
  }
}

EventModal.propTypes = {
  modalIsOpen: bool.isRequired,
  closeModal: func.isRequired,
  eventObject: object.isRequired
};

export default EventModal;
