import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';
import { lawyerService } from '../../services/LawyerService';
import ReactPaginate from 'react-paginate';

class Lawyer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lawyerDocuments: [],
      searchLawyerDocument: {
        title: ''
      },
      pagination: {}
    };
  }

  componentWillMount() {
    lawyerService
      .getAll()
      .then(jsonResponse => {
        this.setLawyerState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  handlePageClick = selectedPage => {
    if (this.state.searchLawyerDocument.title) {
      this.search(selectedPage.selected + 1);
    } else {
      lawyerService
        .getAll(selectedPage.selected + 1)
        .then(jsonResponse => {
          this.setLawyerState(jsonResponse);
        })
        .catch(error => console.log(error));
    }
  };

  setLawyerState = response => {
    let lawyerDocuments = { ...this.state.lawyerDocuments };
    let pagination = { ...this.state.pagination };
    lawyerDocuments = response.lawyer_documents;
    pagination = response.pagination;

    this.setState({ lawyerDocuments });
    this.setState({ pagination });
  };

  updateField = event => {
    const field = event.target.name;
    const searchLawyerDocument = this.state.searchLawyerDocument;
    searchLawyerDocument[field] = event.target.value;

    this.setState({
      searchLawyerDocument
    });
  };

  search = (pageNumber = 1) => {
    lawyerService
      .search(this.state.searchLawyerDocument, pageNumber)
      .then(jsonResponse => {
        this.setLawyerState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Jurist</title>
          <meta
            name="description"
            content="Jurist Frătăuții Vechi. Aici sunt prezentate documentele semnate de juristul din primaria Frătăuții Vechi."
          />
          <meta
            name="keywords"
            content="primaria Frătăuții Vechi, Frătăuții Vechi, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist"
          />
          <meta property="og:title" content="Jurist" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Servicii > </h4>
            <h4 className="standard__generic">Jurist</h4>
          </div>
          <h2 className="standard__main-title">Documente Juridice</h2>
        </div>

        <div className="standard__wrapper">
          <div className="standard__input-box standard__mp-input-box">
            <input
              className="standard__input standard__mp-input"
              label="Cautare Document"
              placeholder="Căutare Document"
              name="title"
              ovalue={this.state.searchLawyerDocument.title}
              onChange={this.updateField}
            />
            <img
              src="/images/icons/search.svg"
              className="standard__search-icon mariage-publications__search-icon"
              alt="search-icon"
              onClick={() => this.search()}
            />
          </div>
          <div
            className={
              'standard__cards ' +
              (this.state.lawyerDocuments.length <= 2
                ? 'standard__projects-wrapper-few'
                : '')
            }
          >
            {this.state.lawyerDocuments.length > 0 ? (
              this.state.lawyerDocuments.map(lawyerDocument => (
                <div
                  key={lawyerDocument.id}
                  className="standard__individual-card"
                >
                  <h3 className="standard__card-title">
                    {lawyerDocument.title}
                  </h3>
                  <h4
                    className="standard__see-project"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${
                          lawyerDocument.document.url
                        }`
                      )
                    }
                  >
                    Vizualizează Document
                  </h4>
                  <img
                    src="/images/icons/file.svg"
                    className="standard__card-img"
                    alt="file"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${
                          lawyerDocument.document.url
                        }`
                      )
                    }
                  />
                </div>
              ))
            ) : (
              <div className="standard__no-projects-box">
                <p className="standard__no-projects">
                  Informații aflate în curs de actualizare.
                </p>
              </div>
            )}
          </div>
          {this.state.pagination.last_page > 1 && (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.pagination.last_page}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Lawyer;
