import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import './ForeignCivilStatus.css';
import '../BirthRegistration/BirthRegistration.css';
import MetaTags from 'react-meta-tags';

class ForeignCivilStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      boxesOptions: {
        openedBox1: false,
        openedBox2: false
      }
    };
  }

  openBox = openBoxType => {
    const currentBox = this.state.boxesOptions[openBoxType];
    let stateBoxes = this.state.boxesOptions;
    stateBoxes = Object.keys(stateBoxes).map(
      option => (stateBoxes[option] = false)
    );

    stateBoxes[openBoxType] = !currentBox;

    this.setState({
      boxesOptions: stateBoxes
    });
  };

  render() {
    return (
      <div className="standard-page">
        <MetaTags>
          <title>Certificate de Stare Civila eliberate in Strainatate</title>
          <meta
            name="description"
            content="Certificate de Stare Civila eliberate in Strainatate Primaria Frătăuții Vechi. Aici sunt prezentate informatii despre Certificate de Stare Civila eliberate in Strainatate."
          />
          <meta
            name="keywords"
            content="primaria Frătăuții Vechi, Frătăuții Vechi, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare"
          />
          <meta
            property="og:title"
            content="Certificate de Stare Civila eliberate in Strainatate"
          />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Servicii > </h4>
            <h4 className="standard__generic standard__title-page">
              Certificate de Stare Civila
            </h4>
          </div>
          <h2 className="standard__main-title birth-registration__main-title">
            Certificate de stare civila eliberate din strainatate
          </h2>
        </div>

        <div className="fc-page">
          <div className="fc-page__light-section">
            <h3 className="standard__main-title">
              Transcrierea Certificatelor de stare civila eliberate din
              strainatate
            </h3>
            <div className="fc-page__important">
              <h4 className="standard__card-description-details">
                Toate traducerile şi legalizările actelor de stare civilă emise
                de autorităţile străine trebuie făcute doar în România.
              </h4>
            </div>
          </div>

          <div className="fc-page__dark-section">
            <h4 className="standard__general-description fc-page__description">
              Actele de stare civilă ale cetăţenilor români, întocmite în
              străinătate, la autorităţile locale competente, se transcriu,
              obligatoriu, în registrele de stare civilă române, în termen de 6
              luni de la întoarcerea în ţară sau de la primirea din străinătate,
              a certificatului ori extrasului de stare civilă.
            </h4>

            <h4 className="standard__general-description fc-page__description">
              Cererea de transcriere, se adresează primăriei localităţii în raza
              căreia îşi are domiciliul solicitantul, însoţită de următoarele
              acte:
            </h4>
            <ol>
              <li className="standard__list-item">
                Certificatul (extrasul) de naştere, căsătorie sau deces (după
                caz) vizat cu apostila conform Convenţiei de la Haga* în
                original şi xerocopia acestuia
              </li>
              <li className="standard__list-item">
                Traducerea în limba română, la un traducător autorizat,
                autentificată de un notar public din România
              </li>
              <li className="standard__list-item">
                Actele de identitate ale părinţilor copilului, paşaport pentru
                părinţii cetăţeni români cu domiciliul în străinătate (în cazul
                cetăţenilor români cu domiciliul în străinătate este necesară
                dovada ultimului domiciliu în ţară, care se solicită la Biroul
                de Evidenţă a Persoanelor), originale şi copii
              </li>
              <li className="standard__list-item">
                Certificatul de căsătorie al părinţilor, iar dacă nu sunt
                căsătoriţi, certificatele de naştere ale acestora
              </li>
              <li className="standard__list-item">
                procură dată la un notar public din ţară, iar din străinătate la
                ambasada statului de reşedinţă, sau la un notar public din ţara
                respectivă, apostilată, în cazul în care se împuterniceşte o
                persoană în vederea transcrierii
              </li>
              <li className="standard__list-item">
                Actul de identitate al persoanei împuternicite, în original
              </li>
              <li className="standard__list-item">
                Declaraţie de naţionalitate
              </li>
              <li className="standard__list-item">
                Declaraţie de domiciliu, în cazul în care părinţii copilului au
                domicilii diferite
              </li>
              <li className="standard__list-item">
                Declaraţie cu privire la numele de familie purtat după
                încheierea căsătoriei
              </li>
            </ol>
          </div>
          <div className="fc-page__light-section">
            <h4 className="standard__general-description">
              În cazul în care cererea de transcriere se depune prin
              împuternicit cu procură specială, pe lângă actele menţionate mai
              sus, este necesară şi declaraţia notarială a cetăţeanului român,
              titular al certificatului în cauză, în care să se specifice dacă
              s-a mai solicitat sau nu transcrierea/înscrierea certificatului în
              registrele de stare civilă române din ţară sau străinătate şi că
              nu mai există un alt act de stare civilă reconstituit.
            </h4>
            <div className="fc-page__important fc-page__second-important">
              <h4 className="standard__card-description-details">Important</h4>
            </div>
            <ol className="foreign-civil-status-page__important">
              <li className="standard__list-item">
                Documentele eliberate de instituţii ale statelor semnatare ale
                Convenţiei de la Haga din 5/10/1961 vor fi în mod obligatoriu
                apostilate
              </li>
              <li className="standard__list-item">
                Sunt scutite de supralegalizare sau apostilare, documentele
                emise de statele cu care România a încheiat tratate, acorduri
                sau convenţii bilaterale de asistenţă juridică în materie civilă
                sau de dreptul familiei
              </li>
              <li className="standard__list-item">
                Documentele care nu se regăsesc în primele două situaţii, vor fi
                în mod obligatoriu supralegalizate, în conformitate cu
                prevederile articolului 162 din Legea 105/1992 cu privire la
                reglementarea raporturilor de drept internaţional privat
              </li>
            </ol>
          </div>
          <div className="fc-page__boxes">
            <div className="fc-page__first-box">
              <div className="fc-page__title-box">
                <h3
                  className="standard__card-title"
                  onClick={event => this.openBox('openedBox1')}
                >
                  Lista Completa a statelor cu care România a incheiat
                  tratate/convenţii/acorduri de asistenţă judiciară
                </h3>
              </div>
              <h4 className="standard__card-description-details">
                Lista completă a statelor cu care România a încheiat
                tratate/convenţii/acorduri de asistenţă judiciară, care prevăd
                scutirea de supralegalizare sau orice altă formalitate:
              </h4>
              <h4
                className={
                  this.state.boxesOptions.openedBox1
                    ? 'standard__card-description-details'
                    : 'standard__card-description'
                }
              >
                Republica Albania, Republica Austria, Regatul Belgiei, Bosnia şi
                Herţegovina, Bulgaria, Republica Ceha, Republica Populară
                Chineză, Republica Populară Democrată Coreană, Croaţia,
                Republica Cuba, Republica Franceză, Republica Moldova, Republica
                Populară Mongolă, Republica Polonă, Federaţia Rusă, Republica
                Serbia, Slovacia, Slovenia, Ucraina, Republica Ungară
              </h4>
              {this.state.boxesOptions.openedBox1 ? (
                <button
                  onClick={event => this.openBox('openedBox1')}
                  className="standard__button birth-registration__button"
                >
                  <h4 className="standard__button-writing birth-registration__button-writing">
                    Citeste mai putin
                  </h4>
                </button>
              ) : (
                <button
                  onClick={event => this.openBox('openedBox1')}
                  className="standard__button birth-registration__button"
                >
                  <h4 className="standard__button-writing birth-registration__button-writing">
                    Citeste mai mult
                  </h4>
                </button>
              )}
            </div>
            <div className="fc-page__second-box">
              <div className="fc-page__title-box">
                <h3
                  className="standard__card-title fc-page__switch"
                  onClick={event => this.openBox('openedBox2')}
                >
                  Convenţia cu privire la suprimarea cerinţei supralegalizării
                  actelor oficiale străine
                </h3>
              </div>
              <h4 className="standard__card-description-details fc-page__switch">
                Convenţia cu privire la suprimarea cerinţei supralegalizării
                actelor oficiale străine încheiată la Haga la 5 octombrie 1961,
                la care România a aderat prin O.G. nr. 66/1999, aprobată prin
                Legea nr. 52/2000, cu modificările şi completările ulterioare,
                se aplică cu următoarele state:
              </h4>
              <h4
                className={
                  this.state.boxesOptions.openedBox2
                    ? 'standard__card-description-details fc-page__switch'
                    : 'standard__card-description fc-page__switch'
                }
              >
                Africa de Sud, Andora, Antigua-Barbuda, Republica Argentina,
                Armenia, Australia, Azerbaidjan, Bahamas, Barbados, Republica
                Belarus, Belize, Botswana, Brunei Darussalam, Republica Cipru,
                Columbia, Confederaţia Elveţiană, Republica Estonia, Republica
                Finlanda, Republica Federală Germania, Republica Elenă (Grecia),
                Insulele Cook, Dominica, Danemarca, Ecuador, Fidji, Grenada,
                Honduras, India, Insulele Marshall, Republica Irlanda, Statul
                Israel, Republica Italiană, Japonia, Kazahstan, Lesotho,
                Republica Letonia, Liberia, Lichtenstein, Republica Lituania,
                Marele Ducat al Luxemburgului, Republica Macedonia, Malawi,
                Republica Malta, Mauriţius, Statele Unite Mexicane, Principatul
                Monaco, Namibia, Niue, Regatul Norvegiei, Noua Zeelandă, Olanda,
                Republica Panama, Republica Portugheză, Serbia şi Muntenegru,
                Sain Kitts and Nevis, Salvador, Sf. Incent şi Grenadine, Sf.
                Lucia, Samoa, San Marino, Seychelles, Spania, Regatul Suediei,
                Republica Suriname, Swaziland, Tonga, Trinidat Tobago, Republica
                Turcia, Regatul Unit al Marii Britanii şi Irlandei de Nord
                Statele Unite ale Americii, Republica Venezuela
              </h4>
              {this.state.boxesOptions.openedBox2 ? (
                <button
                  onClick={event => this.openBox('openedBox2')}
                  className="standard__button birth-registration__button"
                >
                  <h4 className="standard__button-writing birth-registration__button-writing">
                    Citeste mai putin
                  </h4>
                </button>
              ) : (
                <button
                  onClick={event => this.openBox('openedBox2')}
                  className="standard__button birth-registration__button"
                >
                  <h4 className="standard__button-writing birth-registration__button-writing">
                    Citeste mai mult
                  </h4>
                </button>
              )}
            </div>
          </div>
          <div className="fc-page__light-section">
            <h4 className="standard__general-description">
              Sunt supuse supralegalizării actele de la autorităţi şi instituţii
              din străinătate, din state cu care România nu are tratate sau
              convenţii de asistenţă juridică în materie, care urmează a fi
              folosite în România şi pentru care nu există scutire de
              supralegalizare.
            </h4>
          </div>
        </div>
      </div>
    );
  }
}

export default ForeignCivilStatus;
