import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';
import { publicDebatesService } from '../../services/PublicDebatesService';
import ReactPaginate from 'react-paginate';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import archiveJson from '../../resources/archiveYears.json';

class PublicDebates extends Component {
  constructor(props) {
    super(props);

    this.state = {
      debates: [],
      pagination: {},
      searchPublicDebate: {
        title: '',
        archiveYear: 2024
      },
      archiveYears: archiveJson
    };
  }

  componentWillMount() {
    publicDebatesService
      .getAll()
      .then(jsonResponse => {
        this.setDebatesState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  setDebatesState = response => {
    let debates = { ...this.state.debates };
    let pagination = { ...this.state.pagination };
    debates = response.public_debates;
    pagination = response.pagination;

    this.setState({ debates });
    this.setState({ pagination });
  };

  handlePageClick = selectedPage => {
    this.search(selectedPage.selected + 1);
  };
  updateField = event => {
    const field = event.target.name;
    const searchPublicDebate = this.state.searchPublicDebate;
    searchPublicDebate[field] = event.target.value;

    this.setState(
      {
        searchPublicDebate
      },
      () => {
        if (field === 'archiveYear') {
          this.search();
        }
      }
    );
  };

  search = (pageNumber = 1) => {
    publicDebatesService
      .search(this.state.searchPublicDebate, pageNumber)
      .then(jsonResponse => {
        this.setDebatesState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  render() {
    return (
      <div className="standard-page">
        <MetaTags>
          <title>Dezbateri publice</title>
          <meta
            name="description"
            content="Pagina de achizitii a primariei Vicovu de Sus. Aici sunt prezentate achizitiile realizate de primarie"
          />
          <meta
            name="keywords"
            content="primaria Vicovu de Sus, Vicovu de Sus, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, alocatie, anunturi"
          />
          <meta
            property="og:title"
            content="Dezbateri publice Primaria Vicovu de Sus"
          />
          <meta
            property="og:url"
            content="https://www.primaria-vicovudesus.ro/achizitii"
          />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Interes Public > </h4>
            <h4 className="standard__generic">Transparență decizională </h4>
          </div>
          <h2 className="standard__main-title">Dezbateri publice</h2>
        </div>
        <div className="standard__wrapper">
          <div className="standard__input-box standard__mp-input-box">
            <input
              className="standard__input standard__mp-input"
              label="Cautare Document"
              placeholder="Căutare Document"
              name="title"
              ovalue={this.state.searchPublicDebate.title}
              onChange={this.updateField}
            />
            <img
              src="/images/icons/search.svg"
              className="standard__search-icon mariage-publications__search-icon"
              alt="search-icon"
              onClick={() => this.search()}
            />
            <div className="standard__input-box mariage-publications__input-box lead-page__input-box">
              <FormControl>
                <NativeSelect
                  value={this.state.searchPublicDebate.archiveYear}
                  onChange={this.updateField}
                  inputProps={{
                    name: 'archiveYear'
                  }}
                >
                  {this.state.archiveYears.map(archive => (
                    <option value={archive.value}>{archive.name}</option>
                  ))}
                </NativeSelect>
              </FormControl>
            </div>
          </div>
          <div className="standard__cards standard__projects-wrapper-few">
            {this.state.debates.length > 0 ? (
              this.state.debates.map(debate => (
                <div className="standard__individual-card" key={debate.id}>
                  <h3 className="standard__card-title">{debate.title}</h3>
                  <h4 className="standard__card-date">{debate.add_date}</h4>
                  <h4 className="standard__card-responsable">
                    Persoana responsabilă :{' '}
                    {debate.responsable ? debate.responsable : ''}
                  </h4>
                  <h4
                    className="standard__see-project"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${
                          debate.document_announcement.url
                        }`
                      )
                    }
                  >
                    Anunț dezbatere
                  </h4>
                  <h4
                    className="standard__see-project"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${
                          debate.document_procedure.url
                        }`
                      )
                    }
                  >
                    Procedură dezbatere
                  </h4>
                  <h4
                    className="standard__see-project"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${
                          debate.document_centralizator.url
                        }`
                      )
                    }
                  >
                    Centralizator
                  </h4>
                  <h4
                    className="standard__see-project"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${
                          debate.document_minute.url
                        }`
                      )
                    }
                  >
                    Minută dezbatere
                  </h4>
                  <img
                    src="/images/icons/file.svg"
                    className="standard__card-img"
                    alt="file"
                  />
                </div>
              ))
            ) : (
              <div className="standard__no-projects-box">
                <p className="standard__no-projects">
                  Informații în curs de actualizare.
                </p>
              </div>
            )}
          </div>
          {this.state.pagination.last_page > 1 && (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.pagination.last_page}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          )}
        </div>
      </div>
    );
  }
}

export default PublicDebates;
