import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';

class MariageRecord extends Component {
  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Înregistrarea Căsătoriei</title>
          <meta
            name="description"
            content="Inregistrarea Casatoriei Frătăuții Vechi. Aici sunt prezentate documentele necesarea si legea in vigoare pentru inregistrarea casatoriei."
          />
          <meta
            name="keywords"
            content="primaria Frătăuții Vechi, Frătăuții Vechi, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei"
          />
          <meta property="og:title" content="Inregistrarea Casatoriei" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Servicii > </h4>
            <h4 className="standard__generic">Înregistrare Căsătorie </h4>
          </div>
          <h2 className="standard__main-title">Înregistrare Căsătorie</h2>
        </div>

        <div className="standard__wrapper allocations-wrapper">
          <div className="heating-aids">
            <div className="heating-aids__left-side">
              <p className="standard__card-description standard__general-description standard__mariage-record-left">
                Declaraţia de căsătorie se face personal, de către viitorii
                soţi, în scris, la primăria locului de domiciliu sau de
                reşedinţă al unuia dintre soţi.
              </p>
              <p className="standard__card-description standard__general-description standard__mariage-record-left">
                Actele pentru încheierea căsătoriei se depun cu 10 zile
                calendaristice înainte, termen în care se cuprind atât ziua când
                a fost făcută declaraţia de căsătorie, cât şi ziua în care se
                oficiază căsătoria.
              </p>
              <p className="standard__card-description standard__general-description standard__mariage-record-left">
                În declaraţia de căsătorie, viitorii soţi vor menţiona regimul
                matrimonial ales, ei putând opta între:
              </p>
              <ol className="standard__document-list">
                <li className="standard__list-item">
                  Regimul comunităţii legale
                </li>
                <li className="standard__list-item">
                  Regimul separaţiei de bunuri
                </li>
                <li className="standard__list-item">
                  Regimul comunităţii convenţionale
                </li>
              </ol>
              <p className="standard__card-description standard__general-description standard__mariage-record-left">
                În situaţia în care viitorii soţi stabilesc un alt regim
                matrimonial decît cel al comunităţii legale aceştia vor prezenta
                convenţia matrimonială autentificată de către un notar public.
              </p>
              <h3 className="standard__submitted-title sa-documents-title  allocations-title">
                Documente necesare pentru încheierea căsătoriei între cetăţeni
                români
              </h3>
              <ol className="standard__document-list">
                <li className="standard__list-item">
                  Actul de identitate, în original şi copie (trebuie să aibă
                  termen de valabilitate atât la depunerea declaraţiei de
                  căsătorie cât şi la încheierea căsătoriei)
                </li>
                <li className="standard__list-item">
                  Certificatul de naştere, în original şi copie
                </li>
                <li className="standard__list-item">
                  Certificatul medical privind starea sănătăţii (certificatele
                  medicale prenupţiale- sunt valabile 14 zile de la data
                  emiterii şi trebuie să cuprindă menţiunea expresă că persoana
                  se poate sau nu se poate căsători)
                </li>
                <li className="standard__list-item">
                  Acte, în original şi în copii traduse şi legalizate ori
                  certificate de ofiţerul de stare civilă, din care să rezulte
                  desfacerea căsătoriei anterioare, dacă este cazul
                  <ol className="standard__document-list">
                    <li className="standard__list-item">
                      Certificatul de deces al fostului soţ
                    </li>
                    <li className="standard__list-item">
                      Certificatul de despărţenie sau de divorţ (eliberat în
                      perioada anilor 1951 - 1960)
                    </li>
                    <li className="standard__list-item">
                      Certificatul de naştere sau de căsătorie, cu menţiunea de
                      desfacere a căsătoriei
                    </li>
                    <li className="standard__list-item">
                      Sentinţa de divorţ rămasă definitivă şi irevocabilă
                    </li>
                  </ol>
                </li>
              </ol>
            </div>
            <div className="heating-aids__right-side allocations-right-side">
              <h3 className="standard__submitted-title sa-documents-title standard__child-allowances-title allocations-title">
                Documente necesare pentru încheierea căsătoriei între un
                cetăţean român şi unul străin
              </h3>
              <ol className="document-list allocations-document-right">
                <li className="standard__list-item">
                  Certificat medical prenupţial eliberat de un medic din
                  România, care să poarte menţiunea <i>«Se poate căsători»</i>
                </li>
                <li className="standard__list-item">
                  Paşaport (original şi copie xerox) - în care să fie aplicată
                  viza de intrare pe teritoriul României; – sau act de
                  identitate, original şi copie xerox (în cazul cetăţenilor din
                  statele membre ale U.E.)
                </li>
                <li className="standard__list-item">
                  Certificat de naştere, original şi copie xerox şi după caz:
                  apostilat cu Apostila Convenţiei de la Haga sau supralegalizat
                  în funcţie de statul emitent; Certificatul de naştere va fi
                  tradus şi legalizat la un birou notarial de pe teritoriul
                  României
                </li>
                <li className="standard__list-item">
                  Dovada eliberată / autentificată de misiunile diplomatice /
                  oficiile consulareacreditate în România din care să rezulte că
                  cetăţeanul străin îndeplineşte condiţiile de fond cerute de
                  legea sa naţională şi nu există impedimente pentru încheierea
                  căsătoriei în România
                </li>
                <li className="standard__list-item">
                  Un document care să ateste domiciliul din străinătate al
                  cetăţeanului sau declaraţie pe propria răspundere dată la un
                  birou notarial din România privind domiciliul din ţara de
                  origine
                </li>
                <p className="standard__card-description standard__general-description standard__description-right-side">
                  După caz, dovada desfacerii căsătoriilor anterioare prin
                  sentinţe de divorţ sau certificate de deces însoţite de
                  certificatele de căsătorie, apostilate sau supralegalizate în
                  funcţie de ţară ( vezi afişul cu privire la Apostila
                  Convenţiei de la Haga), traduse şi legalizate de un birou
                  notarial de pe teritoriul României
                </p>
                <p className="standard__card-description standard__general-description standard__description-right-side">
                  În cazul cetăţenilor străini care nu cunosc limba română, la
                  încheierea căsătoriei este obligatorie prezenţa unui interpret
                  autorizat
                </p>
              </ol>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MariageRecord;
