import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';

class TwinCities extends Component {
  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Frătăuții Vechi, Orase Infratite</title>
          <meta
            name="description"
            content="Frătăuții Vechi - Cesson, France, Orase Infratite."
          />
          <meta
            name="keywords"
            content="primaria Frătăuții Vechi, Frătăuții Vechi, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta
            property="og:title"
            content="Frătăuții Vechi - Cesson, France, Orase Infratite"
          />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Despre Vicov > </h4>
            <h4 className="standard__generic">Prezentare Generala </h4>
          </div>
          <h2 className="standard__main-title">Orase Infratite</h2>
        </div>

        <div className="twin-cities__main-content" />
        <div className="section-separator" />
        <div className="standard-page__content">
          <div className="standard-page__content--left-side" />
          <div className="standard-page__content--right-side" />
        </div>
      </div>
    );
  }
}

export default TwinCities;
