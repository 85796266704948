import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';
import { convocationAgendaService } from '../../services/ConvocationAgendaService';
import ReactPaginate from 'react-paginate';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import archiveJson from '../../resources/archiveYears.json';

class ConvocationAgenda extends Component {
  constructor(props) {
    super(props);

    this.state = {
      convocationAgenda: [],
      searchConvocationAgenda: {
        title: '',
        archiveYear: 2024
      },
      archiveYears: archiveJson,
      pagination: {}
    };
  }

  componentWillMount() {
    convocationAgendaService
      .getAll()
      .then(jsonResponse => {
        this.setConvocationAgendaState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  handlePageClick = selectedPage => {
    this.search(selectedPage.selected + 1);
  };

  setConvocationAgendaState = response => {
    let convocationAgenda = { ...this.state.convocationAgenda };
    let pagination = { ...this.state.pagination };
    convocationAgenda = response.convocation_agendas;
    pagination = response.pagination;

    this.setState({ convocationAgenda });
    this.setState({ pagination });
  };

  updateField = event => {
    const field = event.target.name;
    const searchConvocationAgenda = this.state.searchConvocationAgenda;
    searchConvocationAgenda[field] = event.target.value;

    this.setState(
      {
        searchConvocationAgenda
      },
      () => {
        if (field === 'archiveYear') {
          this.search();
        }
      }
    );
  };

  search = (pageNumber = 1) => {
    convocationAgendaService
      .search(this.state.searchConvocationAgenda, pageNumber)
      .then(jsonResponse => {
        this.setConvocationAgendaState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Convocare si ordine de zi</title>
          <meta
            name="description"
            content="Convocare si ordine de zi Primaria Frătăuții Vechi. Aici sunt prezentate detaliile cu privire la convocarile consiliului."
          />
          <meta
            name="keywords"
            content="primaria Frătăuții Vechi, Frătăuții Vechi, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, Proiecte Finalizate, autorizatii de construire"
          />
          <meta property="og:title" content="Convocare si ordine de zi" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Prezentare > </h4>
            <h4 className="standard__generic">Convocare și ordine de zi</h4>
          </div>
          <h2 className="standard__main-title">Convocare și ordine de zi</h2>
        </div>
        <div className="standard__wrapper">
          <div className="standard__input-box standard__mp-input-box">
            <input
              className="standard__input standard__mp-input"
              label="Cautare Document"
              placeholder="Căutare Document"
              name="title"
              ovalue={this.state.searchConvocationAgenda.title}
              onChange={this.updateField}
            />
            <img
              src="/images/icons/search.svg"
              className="standard__search-icon mariage-publications__search-icon"
              alt="search-icon"
              onClick={() => this.search()}
            />
            <div className="standard__input-box mariage-publications__input-box lead-page__input-box">
              <FormControl>
                <NativeSelect
                  value={this.state.searchConvocationAgenda.archiveYear}
                  onChange={this.updateField}
                  inputProps={{
                    name: 'archiveYear'
                  }}
                >
                  {this.state.archiveYears.map(archive => (
                    <option value={archive.value}>{archive.name}</option>
                  ))}
                </NativeSelect>
              </FormControl>
            </div>
          </div>

          <div
            className={
              'standard__cards ' +
              (this.state.convocationAgenda.length <= 2
                ? 'standard__projects-wrapper-few'
                : '')
            }
          >
            {this.state.convocationAgenda.length > 0 ? (
              this.state.convocationAgenda.map(convocationAgenda => (
                <div
                  className="standard__individual-card"
                  key={convocationAgenda.id}
                >
                  <h3 className="standard__card-title">
                    {convocationAgenda.title}
                  </h3>
                  <h4 className="standard__card-date">
                    {convocationAgenda.add_date}
                  </h4>
                  <h4
                    className="standard__see-project"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${
                          convocationAgenda.document.url
                        }`
                      )
                    }
                  >
                    Vizualizează Document
                  </h4>
                  <img
                    src="/images/icons/file.svg"
                    className="standard__card-img"
                    alt="file"
                    onClick={() =>
                      window.open(
                        `${process.env.REACT_APP_API_URL}${
                          convocationAgenda.document.url
                        }`
                      )
                    }
                  />
                </div>
              ))
            ) : (
              <div className="standard__no-projects-box">
                <p className="standard__no-projects">
                  Nu sunt rezultate pentru căutare.
                </p>
              </div>
            )}
          </div>
          {this.state.pagination.last_page > 1 && (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.pagination.last_page}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          )}
        </div>
      </div>
    );
  }
}

export default ConvocationAgenda;
