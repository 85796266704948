import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import './CivilStatus.css';
import MetaTags from 'react-meta-tags';

class CivilStatus extends Component {
  render() {
    return (
      <div className="standard-page">
        <MetaTags>
          <title>Certificate de Stare Civila</title>
          <meta
            name="description"
            content="Certificate de Stare Civila Primaria Frătăuții Vechi. Aici sunt prezentate detaliile cu privire la Certificate de Stare Civila."
          />
          <meta
            name="keywords"
            content="primaria Frătăuții Vechi, Frătăuții Vechi, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere"
          />
          <meta property="og:title" content="Certificate de Stare Civila" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Servicii > </h4>
            <h4 className="standard__generic">Starea civilă </h4>
          </div>
          <h2 className="standard__main-title">Certificate de Stare Civilă</h2>
        </div>

        <div className="standard__wrapper">
          <h3 className="standard__submitted-title standard__civil-status-title  standard__common-title">
            Eliberarea/preschimbarea certificatelor de stare civila
          </h3>
          <h4 className="standard__generic standard__mariage-record-left standard__civil-status-info">
            Certificatele de stare civilă se eliberează la cerere persoanei
            îndreptăţite, pe baza înregistrărilor şi a menţiunilor înscrise în
            registrele de stare civilă, în următoarele cazuri
          </h4>
          <div className="standard__mariage-record-left">
            <ul className="standard__unordered-document-list">
              <li className="standard__list-item">
                In caz de pierdere, furt sau distrugere
              </li>
              <li className="standard__list-item">
                In baza hotărârii judecătoreşti sau a deciziilor administrative
              </li>
            </ul>

            <h4 className="standard__generic standard__civil-status-info">
              Certificatele de stare civilă se eliberează numai cetăţenilor care
              posedă act de identitate (în original şi copie). Cetăţenii care nu
              au acte de identitate vor fi îndrumaţi să solicite mai întâi la
              Serviciul de Evidenţă a Persoanelor de care aparţin, eliberarea
              unei cărţi de identitate provizorii.
            </h4>
            <h4 className="standard__generic standard__civil-status-info">
              Cetăţenilor români cu domiciliul în străinătate şi cetăţenilor
              străini ale căror acte de stare civilă sunt înregistrate în ţară
              li se vor elibera certificate pe baza paşaportului (original şi
              copie).
            </h4>
            <h4 className="standard__generic standard__civil-status-info">
              Persoanele care nu se pot deplasa personal pentru a obţine
              certificatele de stare civilă, pot împuternici, printr-o procură
              specială o persoană în vederea ridicării acestora.
            </h4>
            <h4 className="standard__generic standard__civil-status-info">
              Procura specială poate fi autentificată:
            </h4>
            <ul className="standard__unordered-document-list">
              <li className="standard__list-item">
                în ţară de un notar public
              </li>
              <li className="standard__list-item">
                în străinătate, la misiunea diplomatică sau oficiul consular al
                României din statul de reşedinţă sau la un notar public străin,
                cu apostilă, conform Convenţiei de la Haga.
              </li>
            </ul>
            <h4 className="standard__generic standard__civil-status-info">
              Certificatul de naştere se eliberează numai titularului actului.
            </h4>
            <h4 className="standard__generic standard__civil-status-info">
              Pentru copiii minori, care nu au acte de identitate, precum şi
              pentru cei puşi sub interdicţie, certificatul de naştere se
              eliberează părintelui sau reprezentantului legal.
            </h4>
            <h4 className="standard__generic standard__civil-status-info">
              Certificatul de căsătorie se poate elibera unuia dintre soţi.
            </h4>
            <h4 className="standard__generic standard__civil-status-info">
              Certificatul de deces se eliberează membrilor familiei sau altor
              persoane îndreptăţite.
            </h4>
          </div>
        </div>
      </div>
    );
  }
}

export default CivilStatus;
