import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';

class TaxesPage extends Component {
  render() {
    return (
      <div className="standard-page">
        <MetaTags>
          <title>Plata impozitelor, taxelor locale și amenzilor</title>
          <meta
            name="description"
            content="plata impozitelor,  taxelor locale și amenzilor,"
          />
          <meta
            name="keywords"
            content=" institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, Proiecte Finalizate, autorizatii de construire"
          />
          <meta
            property="og:title"
            content="Plata impozitelor,  taxelor locale și amenzilor"
          />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Interes public > </h4>
            <h4 className="standard__generic">
              Plata impozitelor, taxelor locale și amenzilor{' '}
            </h4>
          </div>
          <h2 className="standard__main-title">
            Plata impozitelor, taxelor locale și amenzilor
          </h2>
        </div>

        <div className="standard__wrapper standard__death-record-wrapper">
          <h3 className="standard__card-title standard__ordinary-title  standard__taxes">
            Pentru plata impozitelor, taxelor locale și amenzilor, Primăria vă
            pune la dispoziție{' '}
            <a
              className="standard__link standard__taxes-link"
              href="https://www.ghiseul.ro/ghiseul/public/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              portalul online Ghiseul.ro
            </a>
            . Acest sistem de plată electronică este disponibil atât pentru
            persoanele fizice, cât și pentru persoanele juridice. Pentru plata
            impozitelor și taxelor locale prin intermediul Ghiseul.ro nu se
            percep comisioane pentru tranzacțiile efectuate.
          </h3>
          <h3 className="standard__card-title standard__ordinary-title standard__taxes">
            Link acces:{' '}
            <a
              className="standard__link standard__taxes-link"
              href="https://www.ghiseul.ro/ghiseul/public/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {' '}
              https://www.ghiseul.ro/ghiseul/public/
            </a>
          </h3>
        </div>
      </div>
    );
  }
}

export default TaxesPage;
