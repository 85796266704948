import React, { Component } from 'react';
import './UrbanismCertificate.css';
import '../StandardPage/StandardPage.css';
import MetaTags from 'react-meta-tags';
import { pugCertificateService } from '../../services/PugCertificateService';
import ReactPaginate from 'react-paginate';
import DownloadCard from '../../components/DownloadCard/DownloadCard';

class UrbanismCertificate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      certificates: [],
      pagination: {}
    };
  }

  componentWillMount() {
    pugCertificateService
      .getAll()
      .then(jsonResponse => {
        this.setUrbanismCertificatesState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  handlePageClick = selectedPage => {
    pugCertificateService
      .getAll(selectedPage.selected + 1)
      .then(jsonResponse => {
        this.setUrbanismCertificatesState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  setUrbanismCertificatesState = response => {
    let certificates = { ...this.state.certificates };
    let pagination = { ...this.state.pagination };
    certificates = response.pug_certificates;
    pagination = response.pagination;

    this.setState({ certificates });
    this.setState({ pagination });
  };

  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Certificate de Urbanism</title>
          <meta
            name="description"
            content="Certificate de Urbanism Frătăuții Vechi. Aici sunt prezentate documentele necesare pentru certificatele de urbanism, dar si listele certificatelor de urbanism."
          />
          <meta
            name="keywords"
            content="primaria Frătăuții Vechi, Frătăuții Vechi, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire, jurist, conducere, consilieri locali, dispozitiile primarului, inregistrarea casatoriei, proiecte in desfasurare, plan urbanistic general, ajutor social"
          />
          <meta property="og:title" content="Certificate de Urbanism" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Servicii > </h4>
            <h4 className="standard__generic">Urbanism </h4>
          </div>
          <h2 className="standard__main-title">Certificate de Urbanism</h2>
        </div>

        <div className="standard__wrapper allocations-wrapper">
          <div className="heating-aids">
            <div className="heating-aids__left-side">
              <h3
                className="standard__submitted-title sa-documents-title"
                id="certificates-main-title"
              >
                Documente si Formulare tip necesare
              </h3>
              <h3
                className="standard__submitted-title sa-documents-title"
                id="permits-side-title"
              >
                Documente necesare pentru eliberarea Certificatului de Urbanism
              </h3>
              <ol className="standard__document-list  sa-list">
                <li className="standard__list-item">
                  Carte Identitate - copie
                </li>
                <li className="standard__list-item">
                  Extrasul de Carte Funciara (dupa caz, nu mai vechi de 30 zile)
                </li>
                <li className="standard__list-item">
                  Plan de incadrare in zona
                </li>
                <li className="standard__list-item">
                  Plan de amplasament si delimitare imobil
                </li>
                <li className="standard__list-item">
                  Taxa eliberare Certificat de Urbanism - copie
                </li>
              </ol>
              <h3
                className="standard__submitted-title sa-documents-title"
                id="permits-side-title"
              >
                Formulare tip necesare
              </h3>
              <div className="standard-cards" id="certificate-download-cards">
                <DownloadCard
                  flexClass="pug-page__individual-card"
                  title="Cerere pentru emiterea certificatului de urbanism"
                  url="/form/cerere_emitere_certificat_urbanism.pdf"
                />
              </div>
              <div className="standard-cards" id="certificate-download-cards">
                <DownloadCard
                  flexClass="pug-page__individual-card"
                  title="Cerere pentru eliberarea autorizatiei de construire"
                  url="/form/cerere_eliberare_autorizație_construire.pdf"
                />
              </div>
              <div className="standard-cards" id="certificate-download-cards">
                <DownloadCard
                  flexClass="pug-page__individual-card"
                  title="Anexa la cererea pentru emiterea autorizatiei de construire/desfiintare"
                  url="/form/cerere_emitere_certificat_urbanism.pdf"
                />
              </div>
              <div className="standard-cards" id="certificate-download-cards">
                <DownloadCard
                  flexClass="pug-page__individual-card"
                  title="Cerere prelungire certificat de urbanism"
                  url="/form/cerere_prelungire_certificat_urbanism.pdf"
                />
              </div>
              <div className="standard-cards" id="certificate-download-cards">
                <DownloadCard
                  flexClass="pug-page__individual-card"
                  title="Cerere prelungirea valabilitatii autorizatiei de construire"
                  url="/form/cerere_prelungire_valabilității_autorizației_de_construire.pdf"
                />
              </div>
              <div className="standard-cards" id="certificate-download-cards">
                <DownloadCard
                  flexClass="pug-page__individual-card"
                  title="DECLARAŢIA privind valoarea reală a lucrărilor executate în baza autorizaţiei de construire/desfiintare"
                  url="/form/declaratie_privind_valoarea_reala_lucrarilor_executate_in_baza_AC.pdf"
                />
              </div>
              <div className="standard-cards" id="certificate-download-cards">
                <DownloadCard
                  flexClass="pug-page__individual-card"
                  title="Anexa 8 cerere autorizatie bransamente"
                  url="/form/Anexa_8_cerere autorizatie_bransamente.pdf"
                />
              </div>
            </div>
            <div className="heating-aids__right-side">
              <h3
                className="standard__submitted-title sa-documents-title"
                id="permits-side-title"
              >
                Lista Certificatelor de Urbanism
              </h3>
              <div className="standard__cards" id="certificate-cards">
                {this.state.certificates.map(certificate => (
                  <div
                    className="standard__individual-card"
                    id="permits-card"
                    key={certificate.id}
                  >
                    <h3
                      className="standard__card-title"
                      id="permits-card-title"
                    >
                      {certificate.title}
                    </h3>
                    <h4
                      className="standard__see-project"
                      onClick={() =>
                        window.open(
                          `${process.env.REACT_APP_API_URL}${certificate.document.url}`
                        )
                      }
                    >
                      Vizualizează Document
                    </h4>
                  </div>
                ))}
              </div>
              {this.state.pagination.last_page > 1 && (
                <ReactPaginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={this.state.pagination.last_page}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  onPageChange={this.handlePageClick}
                  containerClassName={'pagination'}
                  subContainerClassName={'pages pagination'}
                  activeClassName={'active'}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UrbanismCertificate;
