import React, { Component } from 'react';
import './HeatingAids.css';
import MetaTags from 'react-meta-tags';
import '../../resources/StandardPage.css';
import DownloadCard from '../../components/DownloadCard/DownloadCard';

class HeatingAids extends Component {
  render() {
    return (
      <div className="standard">
        <MetaTags>
          <title>Ajutoare incalzire</title>
          <meta
            name="description"
            content="Ajutoare incalzire Frătăuții Vechi. Aici sunt prezentate detaliile legale si cerintele pentru ajutoarele de incalzire."
          />
          <meta
            name="keywords"
            content="primaria Frătăuții Vechi, Frătăuții Vechi, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi, nastere, acte nastere, proiecte finalizate, autorizatii de construire, evenimente, informatii financiare, ajutoare incalzire"
          />
          <meta property="og:title" content="Ajutoare incalzire" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Servicii > </h4>
            <h4 className="standard__generic">Asistență socială </h4>
          </div>
          <h2 className="standard__main-title">Ajutoare de incalzire</h2>
        </div>
        <div className="heating-aids">
          <div className="heating-aids__left-side">
            <div className="heating-aids__left-side-small">
              <h2 className="standard__main-title">Hotărâri de guvern</h2>
              <h4 className="standard__card-title standard__ordinary-title heating-aids__subtitle">
                SEZONUL RECE REPREZINTĂ PERIOADA DE 5 LUNI , CUPRINSĂ ÎNTRE DATA
                DE : 1 NOIEMBRIE 2018 – 31 MARTIE 2019
              </h4>
              <h4 className="standard__card-description standard__general-description heating-aids__info">
                LA STABILIREA VENITULUI NET MEDIU LUNAR PE MEMBRU DE FAMILIE SE
                IAU ÎN CALCUL TOATE VENITURILE NETE REALIZATE ÎN LUNA ANTERIOARĂ
                LUNII ÎN CARE SE SOLICITĂ DREPTUL.
              </h4>
              <p className="standard__card-description standard__general-description heating-aids__info">
                Cu exceptia ajutorului social, alocaţiei pentru susţinerea
                familiei prevăzute de Legea nr. 277/2010 privind alocaţia pentru
                susţinerea familiei, republicată, cu modificările ulterioare, a
                bugetului personal complementar prevăzut de Legea nr. 448/2006
                privind protecţia şi promovarea drepturilor persoanelor cu
                handicap, republicată, cu modificările şi completările
                ulterioare, a ajutoarelor de stat acordate în baza Ordonanţei
                Guvernului nr. 14/2010 privind măsuri financiare pentru
                reglementarea ajutoarelor de stat acordate producătorilor
                agricoli, începând cu anul 2010, aprobată cu modificări şi
                completări prin Legea nr. 74/2010, cu completările ulterioare, a
                burselor de studiu şi a burselor sociale, a sprijinului
                financiar prevăzut de Hotărârea Guvernului nr. 1.488/2004
                privind aprobarea criteriilor şi a cuantumului sprijinului
                financiar ce se acordă elevilor în cadrul Programului naţional
                de protecţie socială «Bani de liceu», cu modificările şi
                completările ulterioare,turil precum şi venie obţinute din
                activităţile cu caracter ocazional desfăşurate de zilieri în
                condiţiile Legii nr. 52/2011 privind exercitarea unor activităţi
                cu caracter ocazional desfăşurate de zilieri, cu modificările şi
                completările ulterioare.
              </p>
            </div>
            <div className="standard__cards">
              <DownloadCard
                requiredWidth="sa-required-width"
                title="DOCUMENTE NECESARE AJUTOR INCALZIRE, ALOCATIE DE SUSTINERE A FAMILIEI ȘI VENIT MINIM GARANTAT"
                url="/documents/Documente_ajutor_incalzire.pdf"
              />
              <DownloadCard
                requiredWidth="sa-required-width"
                title="CUANTUM AJUTOR INCALZIRE"
                url="/documents/cuantum-ajutor-incalzire.docx"
              />
              <DownloadCard
                requiredWidth="sa-required-width"
                title="ANEXA 4 - LISTA BUNURILOR CE CONDUC LA EXCLUDEREA ACORDĂRII 
                AJUTORULUI SOCIAL
                "
                url="/documents/anexa-4-lista-bunuri.doc"
              />
              <DownloadCard
                requiredWidth="sa-required-width"
                title="Cerere solicitare ajutor incalzire"
                url="/documents/Anexa_26-Cerere-ajutor-consumator-vulnerabil.docx"
              />
              <DownloadCard
                requiredWidth="sa-required-width"
                title="SUPLIMENT"
                url="/documents/SUPLIMENT.docx"
              />
            </div>
          </div>
          <div className="heating-aids__right-side">
            <h2 className="heating-aids__main-title" id="right-main-title">
              Acte necesare ajutor incalzire
            </h2>
            <ol className="standard__document-list" id="heating-aids-list">
              <li className="standard__list-item">
                Actele de identitate ale membrilor familiei (CI/BI, certificate
                de naştere copii sub 14 ani)
              </li>
              <li className="standard__list-item">
                Copie certificate de naştere ale copiilor în vârstă de până în
                14 ani;
              </li>
              <li className="standard__list-item">
                Copie certificat de căsătorie sau după caz, copie hotărâre de
                divorț/act notarial;
              </li>
              <li className="standard__list-item">
                Declaraţie pe propria răspundere că persoanele sunt despărţite
                în fapt şi data la care partenerul a părăsit imobilul - dacă
                este cazul.
              </li>
              <li className="standard__list-item">
                Certificat de deces - dacă este cazul
              </li>
              <li className="standard__list-item">
                Copie hotărâre judecătorească definitivă de încredinţare a
                copilului sau de încuviinţare a adopţiei, de plasament familial
                al minorului – dacă este cazul.
              </li>
              <li className="standard__list-item">
                După caz, alte acte doveditoare privind componenţa familiei
              </li>
              <li className="standard__list-item">
                Adeverinţă de salariu pentru fiecare membru al familiei care
                lucrează cu contract legal, în care să se specifice salariul net
                obţinut în luna anterioară depunerii cererii, inclusiv valoarea
                bonurilor de masă sau menţiunea că nu se primesc bonuri din
                partea instituţiei; copie taloane pensie; copii taloane
                indemnizaţie handicap sau orice alte indemnizaţii/stimulente
                acordate conform legii - din luna anterioară depunerii cererii;
                taloane somaj din luna anterioară depunerii cererii;
              </li>
              <li className="standard__list-item">
                Certificat fiscal casierie;
              </li>
              <li className="standard__list-item">Adeverinţe salariat</li>
              <li className="standard__list-item">Fisa calcul casierie;</li>
              <li className="standard__list-item">Adeverinta ANAF casierie;</li>
              <li className="standard__list-item">
                Adeverinta registrul agricol;
              </li>
              <li className="standard__list-item">
                Orice alte documente solicitate, dupa caz.
              </li>
            </ol>
          </div>
        </div>
      </div>
    );
  }
}

export default HeatingAids;
