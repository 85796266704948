import React, { Component } from 'react';
import '../StandardPage/StandardPage.css';
import '../EventPage/EventPage.css';
import './Announcements.css';
import MetaTags from 'react-meta-tags';
import { announcementsService } from '../../services/AnnouncementsService';
import ReactPaginate from 'react-paginate';

class AnnouncementsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      announcements: [],
      pagination: {}
    };
  }

  componentWillMount() {
    announcementsService
      .getAll()
      .then(jsonResponse => {
        this.setAnnouncementState(jsonResponse);
      })
      .catch(error => console.log(error));
  }
  setAnnouncementState = response => {
    let announcements = { ...this.state.announcements };
    let pagination = { ...this.state.pagination };
    announcements = response.announcements;
    console.log(response.announcements);
    pagination = response.pagination;

    this.setState({ announcements });
    this.setState({ pagination });
    console.log(this.state.announcements);
  };

  updateField = event => {
    const field = event.target.name;
    const searchAnnouncement = this.state.searchAnnouncement;
    searchAnnouncement[field] = event.target.value;

    this.setState(
      {
        searchAnnouncement
      },
      () => {
        if (field === 'archiveYear') {
          this.search();
        }
      }
    );
  };

  handlePageClick = selectedPage => {
    announcementsService
      .getAll(selectedPage.selected + 1)
      .then(jsonResponse => {
        this.setAnnouncementState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  toggleAnnouncementBox = currentAnnouncement => {
    let announcements = this.state.announcements;

    announcements.forEach(announcement => {
      if (announcement.id === currentAnnouncement.id) {
        announcement.openedBox = !announcement.openedBox;
      }
    });

    this.setState({
      announcements
    });
  };

  render() {
    return (
      <div className="standard-page announcements">
        <MetaTags>
          <title>Anunturi</title>
          <meta
            name="description"
            content="Pagina de anunturi a primariei Frătăuții Vechi. Aici sunt prezentate anunturile de la primarie."
          />
          <meta
            name="keywords"
            content="primaria Frătăuții Vechi, Frătăuții Vechi, institutii si insitutii subordonate, stimulente educationale si insertie, proiecte cu finantare externa, certificate de urbanism, rapoarte de activitate, publicatii de casatorie, regulament si comisii, proiecte de hotarari, indemnizatii crestere copil, procese verbale sedinte, transparenta venituri salariale, starea civila, asistenta sociala, consiliul local, interes public, hotarari adoptate, inregistrare casatorie, proiecte depuse, Suceava, Bucovina, trasee montane, achizitie, achizitii, registrul agricol, anunturi "
          />
          <meta property="og:title" content="Anunturi" />
        </MetaTags>
        <div className="standard__first-section">
          <div className="standard__title-box">
            <h4 className="standard__generic">Noutati > </h4>
            <h4 className="standard__generic">Anunturi</h4>
          </div>
          <h2 className="standard__main-title">Anunturi</h2>
        </div>
        <div className="announcements__content">
          {this.state.announcements.length ? (
            <div>
              <div className="events__wrapper">
                {this.state.announcements.map(announcement => (
                  <div key={announcement.id} className="events__individual">
                    <div className="events__date-container">
                      <img
                        src="/images/icons/newspapper.png"
                        alt="Upcoming"
                        className="events__image"
                      />
                      <div
                        className={
                          announcement.important
                            ? 'announcement-important'
                            : 'announcement-unimportant'
                        }
                      >
                        <h4 className="announcement-important-writing">
                          Important
                        </h4>
                      </div>
                    </div>
                    <h3 className="events__main-title">{announcement.title}</h3>
                    <p
                      className={
                        'events__description ' +
                        (announcement.openedBox
                          ? 'standard__card-description-details'
                          : 'standard__card-description')
                      }
                    >
                      {announcement.description}
                    </p>
                    {announcement.openedBox ? (
                      <h4
                        className="standard__see-project"
                        onClick={event =>
                          this.toggleAnnouncementBox(announcement)
                        }
                      >
                        Vezi mai putin
                      </h4>
                    ) : (
                      <h4
                        className="standard__see-project"
                        onClick={event =>
                          this.toggleAnnouncementBox(announcement)
                        }
                      >
                        Vezi mai mult
                      </h4>
                    )}
                    {announcement.document_filename ? (
                      <button
                        className="standard__button"
                        onClick={() =>
                          window.open(
                            `${process.env.REACT_APP_API_URL}${
                              announcement.document.url
                            }`
                          )
                        }
                      >
                        <h4 className="standard__button-writing">
                          Document Anunt
                        </h4>
                      </button>
                    ) : null}
                  </div>
                ))}
              </div>
              <div className="section-separator" />
            </div>
          ) : (
            <div className="events__no-events">
              <p className="events__description events__no-description">
                Nu sunt anunturi adaugate momentan.
              </p>
            </div>
          )}
          {this.state.pagination.last_page > 1 && (
            <ReactPaginate
              previousLabel={'<'}
              nextLabel={'>'}
              breakLabel={'...'}
              breakClassName={'break-me'}
              pageCount={this.state.pagination.last_page}
              marginPagesDisplayed={1}
              pageRangeDisplayed={2}
              onPageChange={this.handlePageClick}
              containerClassName={'pagination'}
              subContainerClassName={'pages pagination'}
              activeClassName={'active'}
            />
          )}
        </div>
      </div>
    );
  }
}

export default AnnouncementsPage;
