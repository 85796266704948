import React, { Component } from 'react';
import '../../../resources/AdminStandardPage.css';
import AuthenticationService from '../../../services/AuthenticationService';
import DeletePUGCertificates from '../../../components/Dialogs/DeleteDialog/DeleteDialog';
import AttachmentField from '../../../components/AttachmentField/AttachmentField';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../../constants/NotificationSystemStyle';
import { verbalSessionsService } from '../../../services/VerbalSessionsService';
import ReactPaginate from 'react-paginate';

class AdminVerbalSessions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabledDocument: false,
      newVerbalSession: {
        title: '',
        document: '',
        add_date: ''
      },
      existingVerbalSessions: [],
      deleteVerbalSession: {
        openedDialog: false,
        deleteId: 0
      },
      pagination: {}
    };

    this.authentication = new AuthenticationService();
  }

  componentWillMount() {
    verbalSessionsService
      .getAll()
      .then(jsonResponse => {
        this.setVerbalSessionsState(jsonResponse);
      })
      .catch(error => console.log(error));
  }

  componentDidMount() {
    this._notificationSystem = this.refs.notificationSystem;
  }

  handlePageClick = selectedPage => {
    verbalSessionsService
      .getAll(selectedPage.selected + 1)
      .then(jsonResponse => {
        this.setVerbalSessionsState(jsonResponse);
      })
      .catch(error => console.log(error));
  };

  setVerbalSessionsState = response => {
    let existingVerbalSessions = { ...this.state.existingVerbalSessions };
    let pagination = { ...this.state.pagination };
    existingVerbalSessions = response.verbal_sessions;
    pagination = response.pagination;

    this.setState({ existingVerbalSessions });
    this.setState({ pagination });
  };

  updateField = event => {
    const field = event.target.name;
    const newVerbalSession = this.state.newVerbalSession;
    newVerbalSession[field] = event.target.value;

    this.setState({
      newVerbalSession
    });
  };

  onDropDocument = documents => {
    let newVerbalSession = { ...this.state.newVerbalSession };
    let reader = new FileReader();
    let self = this;

    reader.onload = function(upload) {
      newVerbalSession.document = documents[0];

      self.setState({ newVerbalSession });
      self.setState({ disabledDocument: true });
    };
    reader.readAsDataURL(documents[0]);
  };

  removeDocument = () => {
    let newVerbalSession = { ...this.state.newVerbalSession };
    newVerbalSession.document = null;

    this.setState({ newVerbalSession });
    this.setState({ disabledDocument: false });
  };

  resetForm = () => {
    let newVerbalSession = { ...this.state.newVerbalSession };
    newVerbalSession = {
      title: '',
      document: '',
      add_date: ''
    };

    this.setState({ newVerbalSession });
    this.setState({ disabledDocument: false });
  };

  handleFormSubmit = event => {
    event.preventDefault();
    const currentId = this.state.newVerbalSession.id;

    const headers = {
      Accept: 'application/json'
    };

    if (this.authentication.loggedIn()) {
      headers['Authorization'] = 'Bearer ' + this.authentication.getToken();
    }

    let url = `${process.env.REACT_APP_API_URL}/api/verbal_sessions/`;
    let callMethod = 'POST';

    if (currentId) {
      url = `${process.env.REACT_APP_API_URL}/api/verbal_sessions/${
        this.state.newVerbalSession.id
      }`;
      callMethod = 'PUT';
    }

    const formattedData = this.buildFormData();

    fetch(url, {
      method: callMethod,
      body: formattedData,
      headers
    })
      .then(this.handleErrors)
      .then(response => response.json())
      .then(jsonResponse => {
        this.resetForm();

        let existingVerbalSessions = this.state.existingVerbalSessions.slice();

        let index = existingVerbalSessions.findIndex(
          existingVerbalSession => existingVerbalSession.id === jsonResponse.id
        );

        if (index !== -1) {
          existingVerbalSessions[index] = jsonResponse;
        } else {
          existingVerbalSessions.push(jsonResponse);
        }

        this.setState({ existingVerbalSessions });

        if (currentId) {
          this._notificationSystem.addNotification({
            message: 'Procesul verbal a fost editat cu success.',
            level: 'success',
            position: 'tc'
          });
        } else {
          this._notificationSystem.addNotification({
            message: 'Procesul verbal a fost adaugat cu success.',
            level: 'success',
            position: 'tc'
          });
        }
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  buildFormData() {
    let formData = new FormData();
    formData.append('verbal_session[title]', this.state.newVerbalSession.title);

    formData.append(
      'verbal_session[add_date]',
      this.state.newVerbalSession.add_date
    );

    if (this.state.newVerbalSession.document) {
      let document = this.state.newVerbalSession.document;
      formData.append(`verbal_session[document]`, document, document.name);
    }

    return formData;
  }

  setVerbalSessionsForEdit = newVerbalSessionDoc => {
    let newVerbalSession = { ...this.state.newVerbalSession };
    newVerbalSession = Object.assign({}, newVerbalSessionDoc);
    this.setState({ newVerbalSession });

    if (newVerbalSession.document.url) {
      this.setState({ disabledDocument: true });
    }
  };

  openDeleteDialog = id => {
    let deleteVerbalSession = { ...this.state.deleteVerbalSession };
    deleteVerbalSession = {
      openedDialog: true,
      deleteId: id
    };

    this.setState({ deleteVerbalSession });
  };

  closeDeleteDialog = () => {
    let deleteVerbalSession = { ...this.state.deleteVerbalSession };
    deleteVerbalSession = {
      openedDialog: false,
      deleteId: 0
    };

    this.setState({ deleteVerbalSession });
  };

  handleDelete = () => {
    verbalSessionsService
      .deleteVerbalSession(this.state.deleteVerbalSession.deleteId)
      .then(() => {
        let existingVerbalSessions = this.state.existingVerbalSessions.slice();
        existingVerbalSessions = existingVerbalSessions.filter(
          verbalSession =>
            verbalSession.id !== this.state.deleteVerbalSession.deleteId
        );
        this.setState({ existingVerbalSessions });

        this.closeDeleteDialog();

        this._notificationSystem.addNotification({
          message: 'Procesul verbal a fost stears cu success.',
          level: 'success',
          position: 'tc'
        });
      })
      .catch(error => {
        this._notificationSystem.addNotification({
          message:
            'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
          level: 'error',
          position: 'tc'
        });
      });
  };

  handleErrors = response => {
    if (!response.ok) {
      throw Error(response.statusText);
    }
    return response;
  };

  render() {
    return (
      <div className="admin-standard-page">
        <div className="admin-standard-page__title">
          <h3 className="admin-page__profile-title profile-title-specific">
            Administrație
          </h3>
          <h1 className="admin-switch-title default-title">
            Procese Verbale Sedinte
          </h1>
        </div>
        <div className="title-line" />
        <div className="admin-standard-page__content">
          <div className="admin-standard-page__create-project">
            <div className="card">
              <form className="admin-form" onSubmit={this.handleFormSubmit}>
                <h2 className="admin-form__title">
                  Adaugă un proces verbal nou
                </h2>

                <div className="admin-form__field">
                  <h4 className="label-title label-title-provisions">
                    Titlu Proces Verbal*
                  </h4>
                  <input
                    required
                    className="general-field title-field"
                    type="text"
                    name="title"
                    onChange={this.updateField}
                    value={this.state.newVerbalSession.title}
                  />
                  <h4 className="label-title label-title-provisions">
                    Dată Proces Verbal*
                  </h4>
                  <input
                    required
                    className="general-field date-field"
                    type="date"
                    name="add_date"
                    onChange={this.updateField}
                    value={this.state.newVerbalSession.add_date}
                  />
                </div>
                <div className="admin-form__upload">
                  <h4 className="label-title label-title-provisions">
                    Document proces verbal:
                  </h4>
                  <AttachmentField
                    onDrop={this.onDropDocument}
                    removeDocument={this.removeDocument}
                    disabledDocuments={this.state.disabledDocument}
                    document={this.state.newVerbalSession.document}
                    documentFilename={
                      this.state.newVerbalSession.document_filename
                    }
                    type="application/pdf"
                  />
                </div>
                <div className="form-buttons">
                  <div className="admin-form__submit">
                    <button
                      type="submit"
                      className="submit-button general-button"
                    >
                      {this.state.newVerbalSession.id
                        ? 'Editează Convocare'
                        : 'Adaugă Convocare'}
                    </button>
                  </div>
                  {Object.values(this.state.newVerbalSession) && (
                    <button
                      type="button"
                      className="general-button reset-button"
                      onClick={() => this.resetForm()}
                    >
                      Resetează câmpurile
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
          {this.state.existingVerbalSessions.length > 0 && (
            <div className="admin-standard-page__existing-items">
              <div className="card">
                <div className="existing-items">
                  <h2 className="existing-items-title label-title ">
                    Procese verbale existente
                  </h2>
                  <div className="existing-items__content">
                    {this.state.existingVerbalSessions.map(verbalSession => (
                      <div
                        className="existing-items__content-card"
                        key={verbalSession.id}
                      >
                        <div className="card-content provisions-title-content">
                          <h3 className="major-provisions-title">
                            {verbalSession.title}
                          </h3>
                        </div>
                        <div className="existing-items__actions-card">
                          <div
                            className="provisions-title-content"
                            onClick={() =>
                              this.setVerbalSessionForEdit(verbalSession)
                            }
                          >
                            <h4 className="action-title"> Editează</h4>
                          </div>

                          <div
                            className="provisions-title-content"
                            onClick={() =>
                              this.openDeleteDialog(verbalSession.id)
                            }
                          >
                            <h4 className="action-title delete-button">
                              {' '}
                              Șterge
                            </h4>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <DeletePUGCertificates
                    opened={this.state.deleteVerbalSession.openedDialog}
                    handleClose={this.closeDeleteDialog}
                    handleDelete={this.handleDelete}
                    headline="Sunteti sigur ca doriti sa stergeti aceast proces verbal?"
                    aditionalText="Dupa stergere, aceast proces verbal nu va mai putea fi recuperat."
                  />
                </div>
                {this.state.pagination.last_page > 1 && (
                  <ReactPaginate
                    previousLabel={'<'}
                    nextLabel={'>'}
                    breakLabel={'...'}
                    breakClassName={'break-me'}
                    pageCount={this.state.pagination.last_page}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={this.handlePageClick}
                    containerClassName={'pagination'}
                    subContainerClassName={'pages pagination'}
                    activeClassName={'active'}
                  />
                )}
              </div>
            </div>
          )}
        </div>
        <NotificationSystem
          ref="notificationSystem"
          style={NotificationSystemStyle}
        />
      </div>
    );
  }
}

export default AdminVerbalSessions;
